
$text-1: #000000; //black
$text-2: #ffffff; //white
$text-3: #004C82; //dark-blue
$text-4: #02AFEF; //light blue
$text-5: #555555; //grey
$text-6: #CCCCCC; //light grey
$text-7: #444444; //dark grey
$text-8: #4A4A4A; //darkdark grey

$nissens-blue: #004C82;
$grey: #555555;
$light-grey: #CCCCCC;
$lightlight-grey: #E1E5E7;
$dark-grey: #444444;
$light-blue: #EBEFF2;

$odd-blue: #81C1F1;

$basket-hasitems: #00A405; //light green

$header-background: #174b86; //dark-blue
$add-to-basket-msg: #00375F; //very dark blue

$table-text: #4A4A4A;
$table-header1: #444444; //dark grey
$table-header2: #0078B2; //dark-ish blue
$table-row: rgba(241,241,241,0.5);
$table-bg: #F7F7F7;
$table-border: #E7E7E7;
$table-border-dark: #979797;

$basket-header: #0078B2;
$modal-header: #0078B2;

$login-background1: #F5F7F8;
$login-background2: #E1E5E7;

$list-hover: #5897fb;

// Gradients
$gradient-bg: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0) 100%);
$gradient-1: linear-gradient(180deg, #00BF2A 0%, #00A000 100%); // Green
$gradient-2: linear-gradient(0deg, #EDD03A 0%, #FBD75C 100%);   // Yellow
$gradient-3: linear-gradient(180deg, #BE2737 0%, #A11F2D 100%); // Red
