.c-radio {
    input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &__styling {
        &:before {
            content: '';
            background: #fff;
            border-radius: 50%;
            border: 1px solid $nissens-blue;
            display: inline-block;
            width: 18px;
            height: 18px;
            position: relative;
            vertical-align: top;
            cursor: pointer;
        }
    }

    &__text {
        font-family: "Source Sans Pro", Arial;
        font-size: 18px;
        font-weight: 600;
        color: $nissens-blue;
    }

    input[type="radio"]:checked + input + label::before,
    input[type="radio"]:checked + label::before {
        background-color: #004C82;
        box-shadow: inset 0 0 0 3px #fff;
    }
}
