.frontpage {
    a {
        &:hover {
            text-decoration: none;
            color: #fff;
        }
    }

    &__search {

        &__header {
            font-size: 36px;
            line-height: 45px;
            text-align: center;
            padding: 15px;
        }

        &__searchfields {
            height: 360px;
            border-radius: 10px;
            background-color: #EBEFF2;
        }
    }
}


.frontpage-top {
    position: absolute;
    top: -250px;
    margin: 0;
    color: #fff;
    font-size: 14px;

    @include media-breakpoint-down(md) {
        top: -230px;
        font-size: 14px;
        margin: 0px;
    }

    &__article {
        width: 750px;

        @include media-breakpoint-down(md) {
            width: 75%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    h3 {
        font-size: 36px;
        font-weight: 600;

        @include media-breakpoint-down(lg) {
           font-size: 28px;
        }

        @include media-breakpoint-down(md) {
           font-size: 22px;
        }
    }
}

.frontpage-bottom {
    margin: 40px;
}
