
html, body {
    height: 100%;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    overflow-x: hidden;

    .page-wrapper {
        position: absolute;
        width: 100%;
    }

    .page-container {
        position: relative;
        height: 100%;
    }

    &.loginPage, &.userPage, &.LandingPage {
        background: $nissens-blue;
    }

    &.LandingPage{
        .page-wrapper {
        background-color: white;
    }
    }
}

h1 {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 0;
}

h3 {
    font-size: 22px;
    font-weight: 300;
}

@media print {
    .checkout {
        display: inline;
    }

    .checkout__footer-left {
        display: none !important;
    }
}
