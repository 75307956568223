﻿.quickorder {
    &__amount {
        display: inline-block;

        .plus, .minus {
            padding: 10px;
            font-size: 22px;
            font-weight: bold;
            color: #000;
            cursor: pointer;

            @include media-breakpoint-down(md) {
                font-size: 14px;
            }

            &:hover {
                text-decoration: none;
            }
        }

        &--text {
            width: 30%;
        }
    }

    &__number {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;

        &--text {
        }

        @include media-breakpoint-down(lg) {
            margin: 0;
        }
    }

    &__line {
        display: flex;
        flex-direction: row;
    }

    &__input {
        width: 200px;

        &--amount {
            max-width: 40px;
            text-align: center;
        }
    }

    &__clear {
        margin-right: 5px;
    }
}
