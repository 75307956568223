﻿body.LandingPage {
    .footer__logo {
        &.footer__logo--top {
            display: none;
        }
    }
}

.landingpage {

    &__header {
        margin: 4px -5px;
        padding: 12px 0;
        border-bottom: 1px solid #004C82;

        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
        }

        &__logo {
            max-width: 350px;
            max-height: 80px;

            svg {
                max-height: 80px;
            }
        }
    }

    &__content {
        .productdetails {

            &__content {
                padding: 4px 0;

                &__rowwrap {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                }
            }

            &__images, &__info {
                width: 50%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            &__info {
                &__info {

                    ul{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
