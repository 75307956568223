.modal {
    background-color: rgba(0,0,0,0.7);
    display: flex !important;

    .modal-xl {
        max-width: 100%;

        @include media-breakpoint-only(xl) {
            min-width: 1200px;
        }

        @include media-breakpoint-only(lg) {
            min-width: 600px;
        }
    }

    .modal-iframes {
        max-width: 1200px;

        @include media-breakpoint-only(lg) {
            min-width: 600px;
        }

         .modal-content {
             .modal-body {
                overflow-y: auto;
                width: 70vh;
                max-height: 95vh;

                @include media-breakpoint-down(xl) {
                    width: 100%;
                }

                
            }
        }
    }

    .modal-images {
        width: 100%;
        height: auto;

        .modal-body {
            min-height: 600px;
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;
        }


        .MainImageWrap {
            text-align: center;
        }

        .thumbnailList {
            list-style-type: none;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
        }
    }

    .modal-dialog {
        overflow-x: auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }

    .modal-content {
        border: none;
        border-radius: 0;
        box-shadow: 0 2px 34px 0 rgba(0,0,0,0.5);
        background-color: #fff;

        .modal-header {
            display: flex;
            align-items: flex-end;
            padding: 15px 15px 0;
            border-bottom: none;
            height: 60px;
            background: $table-header2;
            transform: translate3d(0,0,0);

            @include media-breakpoint-down(md) {
                height: initial;
            }

            .nav-tabs {
                border: none;
            }

            .nav-link {
                border: none;
                border-radius: 0;
                font-weight: 600;

                @include media-breakpoint-down(md) {
                    max-width: 285px;
                }

                &:not(.active) {
                    color: #fff;
                }
            }

            .fake-nav-link {
                border: none;
                border-radius: 0;
                font-weight: 600;
                color: #fff;
                display: block;
                padding: .5em 1em;

                &:hover {
                    text-decoration: none;
                }

                &:active {
                    text-decoration: none;
                }
            }

            .close {
                background-color: transparent;
                border: none;
                align-self: flex-start;
                padding: 0;
                position: absolute;
                right: 15px;
                top: 15px;
            }

            &__cms {
                flex-direction: column;
                color: #fff;
                padding: 15px;
                align-items: flex-start;
                height: 120px;

                h3 {
                    font-weight: 600;
                }

                .close {
                    margin-top: -85px;
                }
            }
        }

        .modal-table {

            &__header {
                font-size: 15px;
                font-weight: bold;
                border-bottom: 1px solid #ccc;
                padding: 0;
                margin-bottom: 20px;
            }

            &__content {
                margin-bottom: 15px;
            }

            &__actions {
                padding: 18px 0 0;
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #ccc;

                .left {
                    display: flex;
                    flex-direction: row;
                }

                .right {
                    float: right;
                    margin-left: auto;
                }
            }
        }

        .modal-body {
            overflow-y: auto;
            max-height: 80vh;
            width: 70vh;

            @include media-breakpoint-down(xl) {
                width: 100%;
            }

            .tab-pane {
                width: 100%;
            }

            .sectionHeader {
                font-size: 20px;
                color: #4a4a4a;
                font-weight: bold;
                margin-top: 15px;
                margin-left: -12px;
            }
        }

        form {
            @include media-breakpoint-down(xl) {
                font-size: 16px;

                label {
                    margin-bottom: .2rem;
                }
            }
        }
    }
}

.modal-open .modal {
    top: 0px !important;
}

// Specific modal styles

#fancybox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    z-index: 1100;
    display: none;
}

#fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 20px;
    z-index: 1101;
    display: none;
}

#searchcustomer {
    @include media-breakpoint-down(md) {
        fieldset {
            font-size: 16px;

            & > .row {
                width: 45%;
                float: left;

                &:nth-child(2n) {
                    margin-left: 10%;
                }

                input {
                    font-size: 14px;
                    height: 30px;
                    line-height: 30px;
                }
            }
        }

        .c-form__actions {
            margin-right: 0px;
        }
    }
}
