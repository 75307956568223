﻿input[type=email],
input[type=number],
input[type=password],
input[type=text],
input[type=textarea],
textarea {
    height: 35px;
    line-height: 35px;
    border: 1px solid $text-6;
    border-radius: 4px;
    background-color: #FFFFFF;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    margin-bottom: 8px;

    &:focus {
        outline: none;
    }

    &.large {
        height: 60px;
        line-height: 60px;
        border: 2px solid #A01C2B;
        border-radius: 8px;
        font-size: 24px;
        margin-bottom: 12px;
        color: #555 !important;
        background-image: url(../images/FlagsEU.png);
        background-repeat: no-repeat;
        background-position-x: 10px;
        background-size: contain;
        padding-left: 50px;
        text-transform: uppercase;

        &.RegistrationCountry1 { //DK
            background-image: url(../images/FlagsDK.png);
        }

        &.RegistrationCountry47{ //China
            color: #fff !important;
            background-image: none;
            background-color: #004C82;
            border: 2px solid #fff;
        }
    }

    &.large-normal {
        height: 60px;
        line-height: 60px;
        border: 1px solid #CCCCCC;
        border-radius: 8px;
        font-size: 24px;
        margin-bottom: 12px;
        color: #555 !important;
    }

    &.short {
        width: 70px;
    }

    &.medium {
        width: 140px;
    }

    &.readonly {
        background-color: $lightlight-grey;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

input[type=textarea],
textarea {
    height: initial;
    width: 100%;
    margin-top: 5px;

    @include media-breakpoint-up(lg) {
        max-width: 450px;
    }
}

.c-form {

    &__header {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #CCCCCC;
        color: $text-8;
    }

    &__content {
        margin: 20px 0 0;
        color: $text-5;
        font-size: 16px;

        &--border {
            border-bottom: 1px solid #e1e5e7;
            border-top: 1px solid #e1e5e7;
            padding: 15px 0;
        }

        &__headline {
            font-weight: 600;
            margin: 12px 0;
        }

        &__row {
            display: flex;
            align-items: center;
        }

        fieldset {
            margin: 12px 15px;

            @include media-breakpoint-down(md) {
                margin: 0;
            }
        }
        //default ugly and unstyleable file upload button
        .def-file-upload {
            display: none;
        }

        .fileupload {

            &__text {
                margin-left: 10px;
            }

            fieldset {
                margin-bottom: 15px;
            }
        }
    }

    &__actions {
        margin: 10px -5px 20px;
        text-align: right;

        @include media-breakpoint-down(md) {
            justify-content: space-between;
            display: flex;
        }
    }

    &--align-left {
        float: left;
    }

    &--align-right {
        float: right;
        margin-left: auto;
    }

    &__row {
        label {
            word-wrap: break-word;
            max-width: 100%;
        }

        input[type=email], input[type=number], input[type=password], input[type=text], input[type=textarea], textarea {
            margin-bottom: 15px;
        }

        .errorField {
            border: solid 1px #ff0000 !important;
        }
    }
}
