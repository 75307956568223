.product-search {

    .nav-tabs {
        border-bottom: none;
    }

    &__header {
        margin-top: -35px;
        position: relative;

        &__tabs {
            display: inline-block;

            .nav-link {
                border: none;
                color: #fff;
                font-weight: 600;
                line-height: 23px;
                font-size: 18px;
                padding: 6px 35px;

                @include media-breakpoint-down(sm) {
                    padding: 6px 20px;
                }

                &:hover {
                    text-decoration: none;
                }

                &.selected {
                    border-radius: 10px 10px 0 0;
                    background-color: #fff;
                    color: $nissens-blue;
                }
            }
        }

        &__btns {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;

            @include media-breakpoint-down(md) {
                top: -30px;
            }

            .btn {
                margin: 0 2px;

                @include media-breakpoint-down(md) {
                    display: inline;
                }
            }
        }
    }

    &__searcharea {
        @include media-breakpoint-down(sm) {
            background-color: $light-blue;
            border: 1px solid white;
            border-radius: 10px;
            margin: 5px;

            & .col-xs-24::not([type="input"]) {
                padding: 0;
            }
        }
    }

    &__searchheader {
        margin: 15px 0 0;
        font-size: 22px;
        color: $text-8;
        text-align: center;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        &--small {
            margin: 15px 0 5px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                display: block;
                font-size: 18px;
                text-align: center;
            }

            @include media-breakpoint-down(md) {
                line-height: 30px;
                font-weight: 600;
            }
        }
    }

    &__mobileheader {
        display: none;
        font-size: 20px;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    &__filters {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        input[type="checkbox"]:checked + input + label::after,
        input[type="checkbox"]:checked + label::after {
            top: 9px;
            left: 9px;
        }

        .c-checkbox__styling {

            &::before {
                margin: 4px;
            }
        }

        .search-area__fieldset {
            @include media-breakpoint-up(md) {
                > div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin: 6px 0;

                    label {
                        display: inline;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                margin: 0 auto;
                padding: 10px 10%;
                display: flex;
                flex-flow: column wrap;
                max-height: 100%;

                > div {
                    margin: 0;
                }
            }

            @include media-breakpoint-down(xs) {
                max-height: 100%;
            }
        }
    }

    &__inputs {
        margin: 0;
        background-color: $light-blue;
        border-radius: 10px;
        display: flex;

        @include media-breakpoint-down(sm) {
            display: block;
            margin: 0;
            background-color: transparent;
        }

        .search-area {
            flex: 1;
            padding: 15px 115px 0;

            @include media-breakpoint-down(lg) {
                padding: 10px 60px 0;
            }

            @include media-breakpoint-down(md) {
                padding: 10px 25px 0;
            }

            @include media-breakpoint-down(sm) {
                padding: 0;
                width: 100%;
            }

            &--first {
            }

            &--second {
                @include media-breakpoint-up(md) {
                    border-left: 4px solid white;
                    border-right: 4px solid white;
                }
            }

            &--third {
            }

            &__header {
                text-align: center;
                font-size: 22px;
                margin-bottom: 6px;

                @include media-breakpoint-down(sm) {
                    text-align: left;
                    font-size: 20px;
                    margin: 0;
                }

                &--extramargintop {
                    //margin-top: 5px;
                    margin-bottom: 0;
                }

                &.hidden {
                    display: none;
                }
            }

            &__fieldset {
                @include media-breakpoint-down(sm) {
                    display: none;
                    margin: 0 auto;
                    padding: 10px 10%;
                }
            }

            @include media-breakpoint-down(sm) {
                .product-search__show-more {
                    display: none;
                }
            }

            &__row {
                margin: 5px 0;
                position: relative;
                                
                & .search-area__row {
                    margin: 0;
                }

                &.hidden {
                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }

                &.en-US {
                    display: none !important;
                }
            }

            &__input {
                margin-bottom: 12px;

                &:focus {
                    & + .search-area__label {
                        top: -17px;
                        font-size: 12px;
                        opacity: 1;
                        left: 11px;
                    }
                }
            }

            &__label {
                font-size: 16px;
                position: absolute;
                left: 12px;
                top: 5px;
                opacity: 0.5;
                pointer-events: none;
                cursor: text;
                transition: top linear 0.2s, left linear 0.2s, font-size linear 0.2s, opacity linear 0.2s;

                &.label--inactive {
                    display: none;
                }

                &.label--active {
                    top: -17px;
                    font-size: 12px;
                    opacity: 1;
                    left: 11px;
                }

                @include media-breakpoint-down(md) {
                    font-size: 12px;
                }

                @include media-breakpoint-down(lg) {
                    top: 7px;
                    font-size: 14px;
                }
            }

            .btn {
                margin: 0;
                padding: 9px 20px;
                z-index: 14;
            }
        }
    }

    &__show-more {
        color: $nissens-blue;
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
        width: 100%;
        display: inline-block;

        &-btn {
            padding-right: 20px;
            background-position: right center;
            background-repeat: no-repeat;
        }
    }

    &__show-less {
        color: $nissens-blue;
        text-align: center;
        cursor: pointer;
        width: 100%;
        display: inline-block;
        margin-bottom: 20px;

        &-btn {
            padding-right: 20px;
            background-position: right center;
            background-repeat: no-repeat;
            background-image: url(../images/arrow-blue-up.png);
        }
    }

    &__clear {
        text-align: center;
        margin: 15px 0 40px;
        /*a {
            text-decoration: underline;
        }*/
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    [data-js="mobileSearch"] {
        color: $nissens-blue;
        font-weight: 600;
        cursor: pointer;
    }

    [data-js="mobileSearchAreaContainer"], [data-js="mobileSearchProductsContainer"] {
        @include media-breakpoint-down(sm) {
            display: none;
            width: 100%;
        }
    }

    [data-js="mobileSearchArea"], [data-js="mobileSearchProducts"] {
        @include media-breakpoint-down(sm) {
            border-top: 2px solid white;
        }
    }

    [data-js="mobileSearch"], [data-js="mobileSearchArea"], [data-js="mobileSearchProducts"] {
        @include media-breakpoint-down(sm) {
            padding: 12px 20px;

            &::before {
                content: '';
                position: absolute;
                height: 15px;
                width: 15px;
                top: 17px;
                right: 25px;
                overflow: hidden;
                background-color: $nissens-blue;
                transition: all linear 0.2s;
            }

            &::after {
                content: '';
                position: absolute;
                height: 15px;
                width: 15px;
                top: 12px;
                right: 25px;
                overflow: hidden;
                background-color: $light-blue;
                transition: all linear 0.2s;
            }

            &::after, &::before {
                transform: rotate(45deg);
            }

            &.active {
                &::after {
                    top: 27px;
                }

                &::before {
                    top: 22px;
                }
            }

            span {
                pointer-events: none;
            }
        }
    }
}
