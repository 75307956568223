.carmessage {
    height: 37px;
    background-color: #444444;
    color: #fff;
    text-align: center;
    //width: 100%;
    padding: 4px;

    @include media-breakpoint-down(lg) {
        margin: 0 -12%;
    }
}

.productcompare {
    display: flex;
    flex-direction: row;

    .comparetable-container {
        display: flex;
        flex-direction: row;

        @-moz-document url-prefix() {
            .top-cell__extraheight {
                height: 221px !important;
            }
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            .top-cell__extraheight {
                height: 221px !important;
            }
        }

        @supports (-ms-ime-align: auto) {
            .top-cell__extraheight {
                height: 221px !important;
            }
        }

        .table {
            font-size: 15px;

            tr {
                &.stripe {
                    background-color: rgba(235,239,242,0.5);

                    &.active {
                        background-color: $odd-blue;
                    }
                }

                &.active {
                    background-color: $odd-blue;
                }
            }

            th, td {
                padding: 0;
                height: 37px;
                vertical-align: middle;
                padding: 0 2rem 0 1rem;
                border-right: 1px solid #EBEFF2;

                &.firstrow {
                    background-color: rgba(235,239,242,.5);
                }

                &.top-cell--first {
                    vertical-align: bottom;
                }
            }

            .showhidediffs {
                margin-bottom: 130px;
            }

            .top-cell {
                //display: flex;
                //flex-direction: column;
                //justify-content: flex-end;
                height: 220px;

                &__img {
                    width: 180px;
                    height: 120px;
                    margin-bottom: 15px;
                    margin-top: 20px;
                    display: flex;
                    align-items: flex-end;
                }

                &__remove {
                    font-weight: normal;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin-right: -1rem;

                    &__btn {
                        height: 18px;
                        width: 18px;
                        border: 1px solid #CCCCCC;
                        border-radius: 4px;
                        font-size: 24px;
                        margin-left: 9px;
                        cursor: pointer;

                        .cross {
                            transform: rotate(45deg);
                            transform: rotate(45deg);
                            margin-top: -10px;
                            margin-left: 2px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    &__add {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 180px;

        &__text {
            width: 100%;
        }

        &__infotxt {
            color: red;
            display: none;
        }
    }
}

.productcompare-images {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    &__container {
        padding: 0 5px;
        width: 50%;
    }

    .ImageContainer {
        min-height: 300px;
        width: 100%;
        margin-bottom: 4px;
        margin-top: 4px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;

        img {
            width: 100%;
            max-height: 100%;
        }
    }

    .thumbNailContainer {
        margin-bottom: 50px;
    }

    &__header {
        border-bottom: 1px solid #ccc;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 10px 2px;
        font-size: 15px;

        &--left {
            font-weight: 600;
        }

        &__remove {
            font-weight: normal;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &__btn {
                height: 18px;
                width: 18px;
                border: 1px solid #CCCCCC;
                border-radius: 4px;
                font-size: 24px;
                margin-left: 9px;
                cursor: pointer;

                .cross {
                    transform: rotate(45deg);
                    transform: rotate(45deg);
                    margin-top: -10px;
                    margin-left: 2px;
                    font-weight: 600;
                }
            }
        }
    }
}

.webui-popover {
    font-size: 14px;

    .close {
        opacity: 1;
    }
}

.webui-popover-backdrop {
    background-color: rgba(0,0,0,.25);
}
