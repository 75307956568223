﻿.download {
    min-height: 500px;

    .fake-arrow {
        display: inline-block;
        float: right;
        margin-right: 10px;
        transition: all linear 0.2s;
    }

    &__header {
        margin: 60px 0 40px;
        font-size: 36px;
        line-height: 45px;
        text-align: center;

        &--info {
            font-size: 24px;
        }
    }

    &__tabs {
        display: flex;
        align-items: flex-end;
        padding: 15px 15px 0;
        border-bottom: none;
        //height: 60px;
        background: $table-header2;
        font-size: 18px;

        .nav-tabs {
            border: none;
        }

        .nav-link {
            border: none;
            border-radius: 0;
            font-weight: 600;

            @include media-breakpoint-down(md) {
                max-width: 285px;
            }

            &:not(.active) {
                color: #fff;
                cursor: pointer;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    &__upload {
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            margin: 5px 0;
        }
        //default ugly and unstyleable file upload button
        .def-file-upload {
            display: none;
        }

        .fileupload {
            text-align: center;

            &__text {
                margin-left: 10px;
            }

            fieldset {
                margin-bottom: 15px;
            }
        }

        .field-validation-error {
            color: #d9534f;
        }
    }

    &__content {

        &__upload {
            margin-top: 30px;
            margin-bottom: 30px;
            padding-left: 20px;
        }

        &__filter {
            background-color: #EBEFF2;
            padding: 10px 20px;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: flex-start;
            }

            &--text {
                font-weight: 600;
                margin-right: 2rem;

                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }
            }

            &--select {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 2rem;


                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }

                .select2 {
                    min-width: 150px;
                    margin: 0;
                    margin-left: 1rem;
                }
            }
        }

        .filedelete {

            &:hover, &:focus, &:active {
                text-decoration: none !important;
                color: #000 !important;
            }

            .field__btn {
                height: 18px;
                width: 18px;
                border: 1px solid #ccc;
                border-radius: 4px;
                cursor: pointer;
                margin: 0 auto;

                .cross {
                    font-size: 24px;
                    transform: rotate(45deg);
                    margin-top: -9px;
                    margin-left: 1px;
                    font-weight: 600;
                }
            }
        }

        .table {
            font-size: 15px;
        }

        .table-striped {
            tbody {
                tr {
                    &:nth-of-type(odd) {
                        background-color: rgba(235,239,242,0.5);

                        &.active {
                            background-color: $odd-blue;
                        }
                    }

                    &.active {
                        background-color: $odd-blue;
                    }
                }

                td {
                    padding: 1rem;
                    border-right: 1px solid #eceeef;

                    &.right {
                        text-align: right;
                        border-right: none;
                    }
                }
            }
        }
    }
}
