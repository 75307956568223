﻿.checkout {
    &__header {
        margin-top: 60px;
        text-align: center;
    }

    &__address {
        margin-top: 40px;

        &-header {
            text-align: center;
        }

        &-row {
            position: relative;
            margin: 5px 0 15px;                        
        }

        &-row--xs-margin {
            margin-bottom: 5px;
        }

        &-details--with-margin {
            margin-bottom: 22px;
        }

        &-input {
            margin-bottom: 16px;

            &:focus {
                & + .checkout__address-label {
                    top: -17px;
                    font-size: 12px;
                    opacity: 1;
                    left: 11px;

                    @include media-breakpoint-down(md) {
                        width: calc(100% - 10px);
                    }
                }
            }
        }

        &-label {
            font-size: 16px;
            position: absolute;
            left: 12px;
            top: 5px;
            opacity: 0.5;
            pointer-events: none;
            transition: top linear 0.2s, left linear 0.2s, font-size linear 0.2s, opacity linear 0.2s;
            white-space: nowrap;
            overflow: hidden;

            &.label--active {
                top: -17px;
                font-size: 12px;
                opacity: 1;
                left: 11px;

                @include media-breakpoint-down(md) {
                    width: calc(100% - 10px);
                }
            }

            @include media-breakpoint-down(md) {
                font-size: 12px;
                width: calc(100% - 20px);
            }

            @include media-breakpoint-down(lg) {
                top: 7px;
                font-size: 14px;
            }
        }

        &-details {
        }

        &-list {
            list-style-type: none;
            margin: 0 0 12px;
            padding: 0 0 0 20px;
        }

        &-item {
            font-size: 16px;
            line-height: 28px;

            &--first {
                font-weight: 600;
            }
        }

        &-alternative {
            cursor: pointer;
            border-top: 1px solid #ccc;
        }
    }

    &__table {
        &-header {
            background: $table-header2;
            display: block;
            width: 100%;
            height: 60px;
            color: white;
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            line-height: 60px;

            @include media-breakpoint-down(md) {
                background-color: white;
                color: black;
                font-size: 28px;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid $light-grey;
        height: 60px;
        line-height: 50px;
        margin-top: -10px;
        margin-bottom: 80px;
        padding-top: 10px;

        a:not(.btn) {
            text-decoration: underline;
            margin-right: 40px;
        }

        .btn {
            margin-left: 10px;
            line-height: normal;

            &:hover {
                color: white;
            }
        }

        @include media-breakpoint-down(md) {
            border-top: 2px solid $table-border-dark;
            margin-top: 20px;
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            height: 100%;
            margin-bottom: 20px;

            .btn {
                display: none;
            }

            &-left, &-right {
                width: 100%;
                display: flex;
                flex-direction: column;

                a:not(.btn) {
                    margin-right: auto;
                    margin-left: auto;
                    line-height: 23px;
                    margin-bottom: 30px;
                }
            }
        }

        .backToSearchLink {
            position: relative;
            padding-left: 15px;

            &::before {
                content: '';
                display: block;
                border-left: 1px solid $grey;
                border-bottom: 1px solid $grey;
                transform: rotate(45deg);
                position: absolute;
                left: 3px;
                top: 8px;
                height: 8px;
                width: 8px;
            }
        }

        .clearBasketFromCheckout {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8ZyBzdHJva2U9IiM0NDQiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgPHBhdGggZD0iTTE0LjM5Mjg1NzEgNS44MjE0Mjg1N1YxNy4wNzE0Mjg2SDIuNjA3MTQyODZWNS44MjE0Mjg1N004IDkuMDM1NzE0M3Y0LjgyMTQyODZNNSA5LjAzNTcxNDN2NC44MjE0Mjg2TTExIDkuMDM1NzE0M3Y0LjgyMTQyODZNNS44MjE0Mjg1NyAzLjE0Mjg1NzE0VjFoNS4zNTcxNDI4M3YyLjE0Mjg1NzE0TTEgMy4xNDI4NTcxNGgxNXYyLjY3ODU3MTQzSDF6Ii8+ICA8L2c+PC9zdmc+);
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 23px;
        }

        .updateOther {
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8ZyBzdHJva2U9IiM0NDQiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgPHBhdGggZD0iTTEgOC41QzEgNC4zNDYxNTM4NSA0LjM0NjE1Mzg1IDEgOC41IDFjMyAwIDUuNjUzODQ2MiAxLjc4ODQ2MTU0IDYuODA3NjkyMyA0LjM4NDYxNTM4TTE2IDguNWMwIDQuMTUzODQ2Mi0zLjM0NjE1MzggNy41LTcuNSA3LjUtMyAwLTUuNjUzODQ2MTUtMS43ODg0NjE1LTYuODA3NjkyMy00LjM4NDYxNTQiLz4gICAgPHBhdGggZD0iTTE1Ljk0MjMwNzcgMWwtLjU3NjkyMzEgNC40NDIzMDc3LTQuNDQyMzA3NjgtLjU3NjkyMzA4TTEuMDU3NjkyMyAxNmwuNTc2OTIzMDktNC40NDIzMDc3IDQuNDQyMzA3NjguNTc2OTIzMSIvPiAgPC9nPjwvc3ZnPg==);
            background-repeat: no-repeat;
            background-position: left center;
            padding-left: 23px;
        }
    }
}

.shoppingbasket {
    &__table {
        font-size: 15px;
        color: $table-text;

        td {
            line-height: 35px;
            border-left: 1px solid $light-blue;

            &:first-child {
                border-left: none;
            }

            &.right {
                text-align: right;
            }

            &.quantity {
                width: 115px;
            }

            &.price {
                font-weight: 600;
            }

            &.delete{
                text-align: center;
            }
        }

        thead th {
            border-bottom: 2px solid $light-grey;
        }

        .color1 {
            background-color: rgba(241,241,241,0.5);

            td {
                border-left: none;
                border-bottom: 2px solid $light-grey;
                padding: 1.2rem 0.75rem 0.75rem;
            }
        }

        .item_quantity {
            width: 40px;
            margin: 0;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
        }

        a.delete {
            font-weight: bold;
            transform: rotate(45deg);
            display: block;
            font-size: 20px;
            line-height: 24px;
            position: relative;
            top: 5px;

            &:hover, &:active, &:focus {
                text-decoration: none;
                color: inherit;
            }
        }

        .increase {
            width: 90px;
            position: relative;

            > * {
                position: relative;
                float: left;
            }

            a {
                font-size: 26px;
                font-weight: bold;
                margin: 0 0 0 10px;
                user-select: none;

                &.minus {
                    line-height: 33px;
                    margin: 0 12px 0 0;
                }

                &:hover, &:focus, &:active {
                    text-decoration: none;
                    color: inherit;
                    cursor: pointer;
                }
            }
        }

        .hDropdown {
            min-width: 200px;

            & + .select2-container {
                margin: -3px 0 0;
            }
        }

        .price-total {
            font-size: 36px;
            height: 45px;
            line-height: 45px;

            & > div {
                margin-left: 70px;
                display: inline-block;
            }
        }
    }

    &__change-all {
        margin-right: 10px;
    }

    span.stock {
        height: 18px;
        width: 18px;
        display: block;
        margin: 8px auto;

        &.In {
            background: $gradient-1;
        }

        &.Out {
            background: $gradient-3;
        }

        &.partly {
            background: $gradient-2;
        }
    }

    &--desktop {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0 0 20px;

        &-header {
            background-color: $table-text;
            color: white;
            height: 50px;
            line-height: 50px;
            font-size: 18px;
            font-weight: 600;
            padding-left: 20px;
        }
    }

    &__item {
        border: 1px solid $light-grey;
        margin: 10px;

        &-header {
            height: 60px;
            line-height: 60px;
            font-size: 20px;
            padding: 0 10px;
            position: relative;
        }

        &-lineno {
            margin-right: 10px;
            margin-left: 30px;
        }

        &-dropdown {
            width: 70px;
            height: 60px;
            float: right;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                height: 15px;
                width: 15px;
                top: 22px;
                right: 10px;
                overflow: hidden;
                background-color: #004c82;
                transition: all .2s linear;
                transform: rotate(45deg);
            }

            &::after {
                content: '';
                position: absolute;
                height: 15px;
                width: 15px;
                top: 27px;
                right: 10px;
                overflow: hidden;
                background-color: white;
                transition: all .2s linear;
                transform: rotate(45deg);
            }

            &.active {
                &::before {
                    top: 17px;
                }

                &::after {
                    top: 12px;
                }
            }
        }

        &-list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            background-color: $table-bg;
            padding: 0 10px;
        }

        &-item {
            border-top: 1px solid $table-border;
            font-size: 16px;
            height: 35px;
            line-height: 35px;
            position: relative;
            overflow: hidden;

            &:first-child {
                border-top: none;
            }

            &--tall {
                height: 50px;
                line-height: 50px;
            }

            .item-left {
                font-weight: 600;
                width: 40%;
                float: left;

                &--big {
                    width: 80%;
                }
            }

            .item-right {
                width: 60%;
                float: right;

                &--small {
                    width: 20%;
                }
            }

            .increase {
                width: 90px;
                top: 7px;
                position: relative;

                > * {
                    position: relative;
                    float: left;
                }

                a {
                    font-size: 26px;
                    font-weight: bold;
                    margin: 0 0 0 10px;
                    user-select: none;
                    height: 30px;
                    line-height: 35px;

                    &.minus {
                        line-height: 32px;
                        margin: 0 12px 0 0;
                    }

                    &:hover, &:focus, &:active {
                        text-decoration: none;
                        color: inherit;
                        cursor: pointer;
                    }
                }

                .item_quantity {
                    width: 40px;
                    margin: 0;
                    text-align: center;
                }
            }

            span.stock {
                margin: 8px 0;
            }

            .hDropdown {
                min-width: 165px;

                & + .select2-container {
                    margin: -3px 0 0;
                }
            }
        }

        &-misc {
            background-color: $table-bg;
            padding: 10px;
            border-bottom: 1px solid $table-border;
            font-size: 16px;

            &-inner {
                margin: 0 10vw;
            }

            .hDropdown {
                width: 100%;
                height: 50px;
            }

            .select2-selection {
                height: 50px;
            }
        }
    }

    .search-area__label {
        font-size: 16px;
        position: absolute;
        left: 12px;
        top: 5px;
        opacity: 0.5;
        pointer-events: none;
        transition: top linear 0.2s, left linear 0.2s, font-size linear 0.2s, opacity linear 0.2s;

        &.label--inactive {
            display: none;
        }

        &.label--active {
            top: -9px;
            font-size: 12px;
            opacity: 1;
            left: 11px;
        }

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }

        @include media-breakpoint-down(lg) {
            top: 7px;
            font-size: 14px;
        }
    }

    .search-area__row {
        margin: 5px 0;
        position: relative;

        & .search-area__row {
            margin: 0;
        }

        &.hidden {
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }
}

.shoppingbasket--mobile {
    @include media-breakpoint-up(lg) {
        display: none;
    }

    .checkout {
        &__table {
            &-header {
                background: white;
                color: black;
            }
        }
    }

    .delivery-total {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 20px;
    }

    a.delete {
        display: block;
        height: 20px;
        width: 20px;
        font-size: 36px;
        font-weight: 600;
        transform: rotate(45deg);
        position: absolute;
        left: 20px;
        top: -8px;

        &:hover, &:active, &:focus {
            color: black;
            text-decoration: none;
        }
    }

    .price-total {
        text-align: center;
        font-size: 18px;
        font-weight: 600;

        .price {
            font-size: 36px;
        }
    }

    .freight {
        border-bottom: 1px solid $light-grey;
        margin: 0 10vw 10px;
        padding: 0 0 10px;

        .delivery-total {
            font-size: 16px;
        }
    }

    .send-order {
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

.checkoutPages {
    .linkList--item {
        display: inline-block;
        margin-bottom: 5px;
    }
}

.basketContent, .checkout, .content > .col-xl-24 {
    @include media-breakpoint-down(lg) {
        padding: 0;
    }
}

.div-table{
    ul{
        list-style-type: none !important;
    }
}