
.stock-status {
    width: 18px;
    height: 18px;

    &--instock {
        background: $gradient-1;
    }

    &--lowstock {
        background: $gradient-2;
    }

    &--outofstock {
        background: $gradient-3;
    }
}
