.svg {

    &--logo-color{
        display: block;
        position: relative;
    }

    
}

.svg-icon {
    &--arrow-down-grey {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gIDxwYXRoIGQ9Ik0xMiAwTDYuNSA1LjUgMSAwIiBzdHJva2U9IiM1NTUiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+);
    }
    &--arrow-down-blue {
	    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gIDxwYXRoIGQ9Ik0xMiAwTDYuNSA2IDEgMCIgc3Ryb2tlPSIjMDA0QzgyIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==);
    }
    &--cross-white {
	    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICA8cGF0aCBkPSJNMS44OTMxNjg3Ljc4NzEyMTIzTDE1LjM5MjQ4IDE0LjI4NjQzMjVsLTEuNDE0MjEzNTcgMS40MTQyMTM1OEwuNDc4OTU1MTMgMi4yMDEzMzQ3OHoiLz4gICAgPHBhdGggZD0iTTE1LjM5MjQ3OTY1IDIuMjAxMzM0MzNMMS44OTMxNjgzNyAxNS43MDA2NDU2LjQ3ODk1NDggMTQuMjg2NDMyMDYgMTMuOTc4MjY2MS43ODcxMjA3NXoiLz4gIDwvZz48L3N2Zz4=);
    }
    &--check-white {
	    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8cGF0aCBkPSJNMiA1LjZMNC4yODU3MTQzIDggMTAgMiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNGRkYiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==);
    }
}