﻿
/*

	This file is a custom bootstrap file,
	that imports bootstrap components from ./bower_components

*/

@import "../_shared.scss";


$grid-gutter-width: 10px;

// Reset and dependencies
@import "bootstrap/scss/_normalize";
@import "bootstrap/scss/_print";

// Core CSS
@import "bootstrap/scss/_reboot";
//@import "bootstrap/scss/_type";
//@import "bootstrap/scss/_images";
//@import "bootstrap/scss/_code";
@import "bootstrap/scss/_grid";
@import "bootstrap/scss/_tables";
//@import "bootstrap/scss/_forms";
//@import "bootstrap/scss/_buttons";
// Components
@import "bootstrap/scss/_animation";
@import "bootstrap/scss/_dropdown";
//@import "bootstrap/scss/_button-group";
//@import "bootstrap/scss/_input-group";
//@import "bootstrap/scss/_custom-forms";
@import "bootstrap/scss/_nav";
@import "bootstrap/scss/_navbar";
//@import "bootstrap/scss/_card";
//@import "bootstrap/scss/_breadcrumb";
//@import "bootstrap/scss/_pagination";
//@import "bootstrap/scss/_pager";
//@import "bootstrap/scss/_labels";
//@import "bootstrap/scss/_jumbotron";
@import "bootstrap/scss/_alert";
//@import "bootstrap/scss/_progress";
//@import "bootstrap/scss/_media";
//@import "bootstrap/scss/_list-group";
//@import "bootstrap/scss/_responsive-embed";
//@import "bootstrap/scss/_close";

// Components w/ JavaScript
@import "bootstrap/scss/_modal";
//@import "bootstrap/scss/_tooltip";
//@import "bootstrap/scss/_popover";
//@import "bootstrap/scss/_carousel";

// Utility classes
@import "bootstrap/scss/_utilities";
//@import "bootstrap/scss/_utilities-background";
//@import "bootstrap/scss/_utilities-spacing";
//@import "bootstrap/scss/_utilities-responsive";

.container {
    padding-left: 10px;
    padding-right: 10px;
    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

.row {
    @include media-breakpoint-down(md) {
        margin: 0;
    }
    &.no-gutters {
      margin-right: 0;
      margin-left: 0;
    }
    &.no-gutters > [class^="col-"],
    &.no-gutters > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
}