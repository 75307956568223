.rma {
    min-height: 500px;
    margin-bottom: 50px;

    &__btn-wrap {
        margin-bottom: 15px;
    }

    &__savebtn-wrap {
        text-align: left;
        position: relative;

        .savebtn {
            margin-top: 20px;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        .btn-add {
            margin-right: 20px;
        }
    }

    &__space {
        flex: 1;
        padding: 0 5px;
        line-height: 35px;
        text-align: center;

        @include media-breakpoint-down(lg) {
            text-align: left;
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    &__productUpload {
        display: none;
        margin-bottom: 30px;
    }

    &__choose {
        text-align: center;
        margin-bottom: 40px;

        .btn {
            margin: 0 10px;
        }
    }

    &__error-table {
        border: 1px solid #ccc;
        margin-top: 5px;
        color: #555;
        font-size: 15px;
    }

    .field-validation-error {
        color: #d9534f;
        display: block;
        margin-top: 5px;
    }

    .field-validation-valid {
        display: none;
    }

    .input-validation-error {
        border: 1px solid #ff0000;
        background-color: #ffeeee;
    }
    //default ugly and unstyleable file upload button
    .def-file-upload {
        display: none;
    }

    .c-form {
        &__row {
            margin-bottom: 20px;

            &--margin {
                margin-bottom: 40px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
            }

            label {
                display: inline;
                margin: 0;

                &.btn {
                    display: inline-block;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 6px;
                }
            }

            .silverlabels {
                display: flex;

                @include media-breakpoint-down(lg) {
                    flex-direction: column;
                }

                @include media-breakpoint-down(sm) {
                    flex-direction: row;
                }
            }

            select {
                padding: 6px;
                border: 1px solid #ccc;
                border-radius: 4px;
            }

            [type=checkbox] {
                margin-right: 5px;
                position: relative;
                top: 1px;

                @include media-breakpoint-down(md) {
                    width: auto;
                }
            }

            .half {
                @include media-breakpoint-down(md) {
                    width: 45%;
                }
            }

            select,
            textarea,
            input {
                margin-bottom: 0;
            }

            textarea {
                max-width: 100%;
            }

            select,
            .customer-ref,
            .select2-container {
                width: 100%;
            }

            .select2-selection {
                height: 100%;
                padding: 3px 0;

                &__arrow {
                    top: 5px;
                }
            }

            * {
                box-sizing: border-box;
            }

            .input-group-addon {
                padding: 6px 12px;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
                color: #555;
                text-align: center;
                background-color: #eee;
                border: 1px solid #ccc;
                width: 1%;
                white-space: nowrap;
                vertical-align: middle;
                display: table-cell;
            }

            .currency-suffix {
                display: table;
                border-collapse: separate;

                .suffix-value {
                    padding: 6px 12px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1;
                    color: #555;
                    text-align: center;
                    background-color: #eee;
                    border: 1px solid #ccc;
                    width: 1%;
                    white-space: nowrap;
                    vertical-align: middle;
                    display: table-cell;
                    border-left: 0;
                    border-radius: 0px 4px 4px 0px;
                }

                input {
                    width: 100%;
                    padding: 6px 12px;
                    border: 1px solid #ccc;
                    border-radius: 4px 0 0 4px;
                }
            }
        }

        &__content {
            margin: 20px 0;
        }

        &__fileupload {
            margin: 0;
        }

        .hidden-btn {
            display: none;
            margin-left: 10px;
        }

        .helpfield {
            margin-right: 5px;
            margin-left: 5px;

            @include media-breakpoint-down(sm) {
                padding-bottom: 5px;
            }
        }

        .selected-return-address {
            margin-top: 10px;
        }
    }

    &__fileupload {
        label {
            width: auto;
        }

        fieldset {
            margin: 0;
            margin-bottom: 15px;
        }
    }

    &__fileupload-text {
        margin-left: 10px;
    }

    &__add-product {
        text-align: left;
        border-top: 1px solid #ccc;
        padding-top: 20px;
        padding-bottom: 20px;

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    &__collapse-wrap {
        border-top: 1px solid #ccc;
        padding-top: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            margin: 0 10px 20px;

            .btn {
                padding: 9px 15px;
                margin-bottom: 20px;
            }
        }
    }

    &__create-claim {
        border-top: 1px solid #ccc;
        padding: 30px 5px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }

        p {
            margin: 0 auto;
            max-width: 600px;
            color: $text-5;
            font-size: 16px;
            text-align: center;
            display: none;

            @include media-breakpoint-down(sm) {
                flex-basis: 100%;
                order: 1;
            }

            &.active {
                display: block;
            }
        }
    }

    .helpfield {
        position: relative;
        padding: 1px 2px;
        margin-right: 10px;
        display: inline-block;

        &__icon {
            border: 1px solid #000;
            border-radius: 15px;
            padding: 2px 8px;
            font-weight: 700;
            cursor: pointer;
            font-size: 16px;
            line-height: 1em;
            display: inline-block;
            background: #fff;
            color: #000;

            &.inbox {
                position: absolute;
                top: 10px;
                left: 10px;
                cursor: default;
                margin-bottom: 0;
            }
        }


        &__close {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 18px;
            line-height: 18px;
            padding: 5px 10px;
            cursor: pointer;
        }

        &__box {
            position: absolute;
            top: 90%;
            left: 5px;
            border: 2px solid #004c82;
            border-radius: 15px;
            background: #fff;
            padding: 10px 30px 10px 45px;
            z-index: 1;
            width: 250px;
            white-space: normal;
            box-shadow: 0 4px 14px 0 rgba(0,0,0,.5);
            font-size: 15px;
            text-align: left;
            font-weight: normal;

            @include media-breakpoint-up(md) {
                width: 600px;
            }
        }
    }

    &__header {
        margin: 60px 0 0px;
        font-size: 36px;
        line-height: 45px;
        text-align: center;
        padding: 0px 20px;
        position: relative;

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
        }

        .btn-collapse {
            position: absolute;
            top: 0;
            right: 0;
            line-height: 1.3em;

            @include media-breakpoint-down(md) {
                margin: 0 auto;
                margin-bottom: 10px;
                position: static;
            }
        }
    }

    &__create {
        text-align: center;
        padding-bottom: 60px;
        padding-top: 30px;

        .btn--addToBasket {
            float: none;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 40px;
            padding-top: 20px;
        }
    }

    &__text {
        margin: 15px 0 30px;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        padding: 0 200px;

        @include media-breakpoint-down(md) {
            padding: 0 10px;
            font-size: 18px;
            margin: 0px 0 15px;
        }
    }

    &__top-wrap {
        display: none;

        .rma--print & {
            text-align: right;
            display: block;
            margin: 10px 0 30px;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }
    }

    &__top-text {
        @include media-breakpoint-down(md) {
            line-height: 17px;
            font-size: 16px;
        }
    }

    &__footer-wrap {
        text-align: right;
        margin: 5px 0 30px;

        a {
            display: inline-block;
        }
    }

    &__content {
        margin: 20px 0;

        fieldset {
            margin: 0;
        }

        .rma--print & {
            .btn--small {
                box-shadow: 0 2px 4px 0 rgba(0,76,130,.2);
                padding: 9px 20px;
                font-size: 17px;
            }

            .hiddenOnPrint {
                display: none;
            }
        }

        &__filter {
            background-color: #EBEFF2;
            padding: 10px 20px;
            font-size: 16px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 20px;

            @include media-breakpoint-down(lg) {
                input[type=text] {
                    width: 90%;
                }
            }

            @include media-breakpoint-down(md) {
                flex-direction: column;
                align-items: flex-start;

                input[type=text] {
                    width: 65%;
                    margin-bottom: 0px;
                }
            }

            &--headline {
                font-size: 16px;
                font-weight: 600;
                width: 100%;
                background-color: #ebeff2;
                padding: 10px 0px 0;
                text-align: center;
            }

            &--text {
                font-weight: 600;
                margin-right: 2rem;
                margin-bottom: 15px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }
            }

            &--select {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 2rem;


                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }

                .select2 {
                    margin: 0;
                    margin-left: 1rem;
                }
            }

            &--label {
                @include media-breakpoint-down(md) {
                    width: 35%;
                    line-height: 1.3em;
                }
            }

            &--column {
                flex-direction: column;
                align-items: flex-start;
                display: flex;

                @include media-breakpoint-down(md) {
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                    margin-bottom: 15px;
                }

                .select2 {
                    margin-bottom: 0px;

                    @include media-breakpoint-down(md) {
                        width: 65% !important;
                    }

                    @include media-breakpoint-up(lg) {
                        margin-right: 12px;
                        margin-bottom: 8px;
                        max-width: 150px;
                    }

                    @include media-breakpoint-up(xl) {
                        max-width: 187px;
                        margin-right: 0px;
                    }
                }
            }

            &--btn {
                margin-left: 20px;
                margin-top: 6px;
                margin-bottom: 8px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    margin: 10px 0;
                    margin-right: 30px;
                    text-align: right;
                }
            }

            &--date {
                display: flex;

                @include media-breakpoint-down(md) {
                    width: 65%;
                }

                .hasDatepicker {
                    width: 150px;
                }
            }
        }

        .table {
            font-size: 15px;
            border-bottom: 1px solid #eceeef;
            border-right: 1px solid #eceeef;
            border-left: 1px solid #eceeef;
            margin-bottom: 10px;

            &__collapse {
                &.in {
                    display: table-row-group;
                }
            }
        }

        .table-striped {
            tbody {
                tr {
                    &:nth-of-type(odd) {
                        background-color: rgba(235,239,242,0.5);

                        &.active {
                            background-color: $odd-blue;
                        }
                    }

                    &.active {
                        background-color: $odd-blue;
                    }
                }

                td {
                    border-right: 1px solid #eceeef;

                    &.right {
                        text-align: right;
                        border-right: none;
                    }

                    &.center {
                        text-align: center;
                    }
                }
            }
        }
    }

    &__accordion {
        font-weight: bold;
        font-size: 15px;
        line-height: 19px;
        padding: 5px 15px;
        border-bottom: 1px solid #ccc;
        border-top: 0;
        margin-bottom: 15px;

        .rma--print & {
            display: none;
        }

        @include media-breakpoint-down(md) {
            padding: 5px;
        }

        td {
            border: none;
            vertical-align: bottom;
        }

        a {
            color: #fff;
            text-decoration: underline;
            font-weight: normal;
            display: inline-block;
            line-height: 28px;
            margin-right: 20px;
        }

        &--dark {
            background: $table-header2;
            color: white;
            height: 60px;

            @include media-breakpoint-down(md) {
                height: auto;
            }
        }

        &--name {
            font-size: 20px;
            line-height: 28px;
            font-weight: normal;
            margin-left: 10px;
            margin-right: auto;
            overflow: hidden;
            width: 10%;

            @include media-breakpoint-down(lg) {
                font-size: 18px;
                line-height: 21px;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                margin: 0;
                margin-bottom: 10px;
            }
        }

        &--info {
            font-size: 20px;
            line-height: 28px;
            font-weight: normal;
            flex: 1 1 25%;

            @include media-breakpoint-down(lg) {
                font-size: 18px;
                line-height: 21px;
            }

            @include media-breakpoint-down(md) {
                padding: 2px 0;
                flex: 0 0 100%;
            }
        }

        &__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
                align-items: flex-start;
                position: relative;
                padding: 8px 10px 15px;
            }

            &__btn {
                margin: 0 10px;

                &.floatright {
                    margin-right: 10px;
                    margin-left: auto;
                }

                &.right {
                    margin-right: 10px;
                }

                &.btnRight {
                    margin-right: 0px;
                    display: flex;
                    justify-content: flex-end;
                    flex: 0 0 auto;

                    @include media-breakpoint-down(md) {
                        position: absolute;
                        top: 3px;
                        right: 10px;
                    }
                }

                .field__btn {
                    height: 18px;
                    width: 18px;
                    margin-top: 6px;
                    border: 1px solid #fff;
                    border-radius: 4px;
                    cursor: pointer;
                    background: none;
                    color: #fff;
                    padding: 0;

                    .cross {
                        font-size: 24px;
                        transform: rotate(45deg);
                        margin-top: -2px;
                        margin-left: 1px;
                        font-weight: 600;
                    }

                    .collapse {
                        margin-left: 4px;
                        font-size: 16px;
                        margin-top: -8px;
                        display: block;
                    }
                }
            }
        }
    }

    &__back-link {
        text-decoration: underline;
        margin-left: 10px;
    }
}

.rma-excelUpload {
    display: none;
    margin: 0 auto 30px;
    position: absolute;
    z-index: 1;
    top: -40px;
    width: 690px;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 2px 34px 0 rgba(0,0,0,.5);
    @include media-breakpoint-down(md) {
        width: 90%;
        margin: 0 auto 20px;
    }

    &__content {
        padding: 20px;
    }

    &__close {
        display: flex;
        align-items: flex-end;
        padding: 15px 15px 0;
        border-bottom: none;
        height: 60px;
        background-color: #0078b2;

        .close {
            background-color: transparent;
            border: none;
            align-self: flex-start;
            padding: 0;
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }

    &__result {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
    }

    &__headline {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid #ccc;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 7px;
    }

    &__btn {
        border-top: 1px solid #ccc;
        padding-top: 15px;
        text-align: right;
    }
}


.rma-excel {
    display: flex;
    position: absolute;
    top: -40px;
    right: 15px;

    @include media-breakpoint-down(md) {
        top: -60px;
    }

    &__btn {
        display: flex;
        padding-left: 10px;
    }
}