﻿// Custom variables
$grid-columns: 24;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1024px,
  // Extra large screen / wide desktop
  xl: 1570px
);

$container-max-widths: (
  sm: 999px,
  md: 1000px,
  lg: 1000px,
  xl: 1495px
);

// Core variables and mixins
@import "bootstrap/scss/_variables";
@import "bootstrap/scss/_mixins";