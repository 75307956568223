//overwrite hover color
a.reportgenerator__link:hover {
    color: $text-1;
}

.reportgenerator {
    .hideOnNotShowAll {
        display: none;
    }

    .helpfield {
        position: relative;
        padding: 5px 2px;
        text-align: center;

        &__icon {
            border: 1px solid #000;
            border-radius: 15px;
            padding: 2px 8px;
            font-weight: 700;
            cursor: pointer;
            font-size: 16px;
            line-height: 1em;
            display: inline-block;
            margin-bottom: .5rem;

            &.inbox {
                position: absolute;
                top: 10px;
                left: 10px;
                cursor: default;
                margin-bottom: 0;
            }
        }


        &__close {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 18px;
            line-height: 18px;
            padding: 5px 10px;
            cursor: pointer;
        }

        &__box {
            position: absolute;
            top: 90%;
            left: 5px;
            border: 2px solid #004c82;
            border-radius: 15px;
            background: #fff;
            padding: 10px 30px 10px 45px;
            z-index: 1;
            width: 250px;
            white-space: normal;
            box-shadow: 0 4px 14px 0 rgba(0,0,0,.5);
            font-size: 15px;
            text-align: left;

            @include media-breakpoint-up(md) {
                width: 600px;
            }
        }
    }

    .fake-arrow {
        display: inline-block;
        float: right;
        margin-right: 5px;
        padding: 0 5px;
        transition: all linear 0.2s;

        &.arrowUp {
            transform: rotate(180deg);
        }

        &.arrowDown {
            transform: rotate(0deg);
        }
    }

    .multiselect-btns {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
    }

    .btn--toggle {
        position: absolute;
        right: 0;
        margin: 0;
        padding: 0px 20px;
    }

    &__alert {
        margin-top: 20px;
    }

    &__header {
        margin: 60px 0 0;
        font-size: 36px;
        line-height: 45px;
        text-align: center;
    }

    &__create {
        text-align: right;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            margin-top: 20px;
            text-align: center;
        }
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        li {
            margin: 0;
            padding: 0;
            min-width: 120px;

            .c-checkbox {
                margin: 5px;
                line-height: 100%;
            }
        }
    }

    &__filters {
        margin: 30px 0 20px;
        background-color: #EBEFF2;
        padding: 10px 20px;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: flex-start;
        }

        &__text {
            font-weight: 600;
            margin-right: 2rem;

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
            }
        }

        &__filter {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 2rem;

            @include media-breakpoint-down(md) {
                margin-bottom: 10px;
                width: 100%;
                justify-content: space-between;
            }

            &--select {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 2rem;


                @include media-breakpoint-down(md) {
                    margin-bottom: 10px;
                }

                .select2 {
                    min-width: 150px;
                    margin: 0;
                    margin-left: 1rem;
                }

                .select2-selection__rendered {
                    width: 100%;
                }

                .select2-selection__clear {
                    font-size: 24px;
                    margin-right: 8px;
                    font-weight: 500 !important;
                }
            }

            .calender-container {

                .month {
                    margin-bottom: 0;
                }

                .calender {
                    top: 8px;
                }
            }
        }
    }

    &__table-container {
        font-size: 15px;
        margin-top: 5px;
        margin-bottom: 40px;
        min-height: 116px;

        @include media-breakpoint-up(md) {
            min-height: 250px;
        }
    }
    //table on index page
    &__table {
        border-bottom: 2px solid #eceeef;
        display: table;

        tbody {
            tr {
                &:nth-of-type(odd) {
                    background-color: #fff;

                    &.active {
                        background-color: $odd-blue;
                    }
                }

                &:nth-of-type(even) {
                    background-color: rgba(235,239,242,0.5);

                    &.active {
                        background-color: $odd-blue;
                    }
                }

                &.active {
                    background-color: $odd-blue;
                }

                &:hover {
                    background-color: rgba(0,0,0,.075);
                }
            }

            td {
                vertical-align: middle;
                float: none;
                padding: 6px;
            }

            label {
                margin-bottom: 0;
            }
        }

        .right {
            text-align: right;
        }

        .center {
            text-align: center;
        }
    }

    &__subheader {
        font-weight: 700;
        line-height: 19px;

        th {
            border-top: none;
            padding: 6px;
            float: none;

            &.active .fake-arrow {
                background: rgba(235,239,242,0.4);
            }
        }
    }

    &__link {
        text-decoration: underline;
        font-size: 15px;
        margin: 0 10px;
    }

    &__delete {

        &:hover {
            color: #000 !important;
        }

        .field__btn {
            height: 18px;
            width: 18px;
            border: 1px solid #ccc;
            border-radius: 4px;
            cursor: pointer;
            margin: 0 auto;

            .cross {
                font-size: 24px;
                transform: rotate(45deg);
                margin-top: -10px;
                margin-left: 0;
                font-weight: 600;
            }
        }
    }

    &__btn {
        display: inline-block;
        margin: 5px 0;
        margin-left: 20px;
    }

    &__progressbar {
        margin-top: 60px;
        margin-bottom: 60px;

        &__step {
            text-align: center;

            &.active {
                .name {
                    border-radius: 6px;
                    box-shadow: 0 4px 14px 0 rgba(0,0,0,.5);
                    font-weight: 600;

                    &:after {
                        position: absolute;
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 12px solid #fff;
                        top: 61%;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                }

                .progress {
                    background-color: #0078B2;
                }
            }

            &.done {
                //add check mark left of name
                .name {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        margin: auto;
                        opacity: 1;
                        width: 17px;
                        height: 10px;
                        top: 22px;
                        left: -2px;
                        border: 2px solid $nissens-blue;
                        border-top: none;
                        border-right: none;
                        -webkit-transform: rotate(-45deg);
                        transform: rotate(-45deg);
                    }
                }

                .progress {
                    background-color: #0078B2;
                }
            }

            .name {
                font-size: 22px;
                line-height: 28px;
                text-align: center;
                display: inline-flex;
                padding: 15px 25px;
                margin: 0 0 15px;
            }

            .progress {
                background-color: #EBEFF2;
                height: 20px;
                width: 102%;

                &.stepfirst {
                    border-radius: 10px 0 0 10px;
                }

                &.steplast {
                    border-radius: 0 10px 10px 0;
                }
            }
        }
    }

    &__content {

        .rg-header {
            font-size: 36px;
            line-height: 45px;
        }

        .rg-text {
            font-size: 16px;
            line-height: 24px;
            margin: 6px 0 10px;
        }

        &__blackbox {
            margin-bottom: 30px;

            .c-form__content {
                color: #fff;
            }

            &--inner {
                padding: 30px;
                margin-right: 15px;
                background-color: #4A4A4A;
                border-radius: 6px;

                @include media-breakpoint-down(lg) {
                    padding: 30px;
                    margin-right: 10px;
                }
            }

            .rg-header, .rg-text {
                color: #fff;
            }
        }

        .resulttable {
            overflow-x: unset;

            .datafields td:first-child {
                border-left: 1px solid #ebeff2;
            }

            @include media-breakpoint-up(md) {
                .datafields td {
                    &:nth-child(2) {
                        width: 40%;
                    }
                }
            }

            .radiobtn-td {
                padding-right: 0;
            }

            input {
                margin-bottom: 0;
            }

            input[type="text"] {
                &.fill {
                    width: 100%;
                    max-width: 350px;
                }
            }

            .hDropdown {

                & + .select2-container {
                    margin: 0;
                    min-width: 140px;
                }
            }
        }

        .table-striped {
            tbody {
                tr {
                    &:nth-of-type(odd) {
                        background-color: #fff;
                    }

                    &:nth-of-type(even) {
                        background-color: rgba(235,239,242,0.5);
                    }
                }

                td {
                    &.separator__row {
                        font-weight: 600;
                        /* background: #0078b2; */
                        /* color: #fff; */
                        /* padding: .5rem; */
                    }
                }
            }
        }

        .table {
            font-size: 16px;
            //default ugly and unstyleable file upload button
            .def-file-upload {
                display: none;
            }

            .fileupload {

                label {
                    width: auto;
                }

                &__text {
                    margin-left: 10px;
                }

                fieldset {
                }
            }

            label {
                width: 150px;
                word-wrap: break-word;
                white-space: normal;
            }

            &__collapse {
                &.in {
                    display: table-row-group;
                }
            }

            .subheader {
                font-weight: bold;
                font-size: 15px;
                line-height: 19px;
                padding: 6px;
                border-bottom: 1px solid #ccc;
                border-top: 0;

                td {
                    border: none;
                    vertical-align: bottom;
                }

                .minwidth {
                    min-width: 350px;
                }

                .maxwidthtd {
                    max-width: 140px;
                    word-wrap: break-word;
                    white-space: normal;
                }
            }

            .subheader--dark {
                background: $table-header2;
                color: white;
                height: 60px;
            }

            .subheader__content {
                display: flex;
                flex-direction: row;

                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                }

                .field__sortingDD {
                    display: inline-flex;
                }

                &__sorting {
                    font-weight: 400;

                    .hDropdown + .select2-container {
                        min-width: 100px;
                    }
                }

                &__btn {
                    margin: 0 10px;

                    &.floatright {
                        margin-right: 10px;
                        margin-left: auto;
                    }

                    &.right {
                        margin-right: 10px;
                    }

                    .field__btn {
                        height: 18px;
                        width: 18px;
                        margin-top: 6px;
                        border: 1px solid #fff;
                        border-radius: 4px;
                        cursor: pointer;

                        .cross {
                            font-size: 24px;
                            transform: rotate(45deg);
                            margin-top: -2px;
                            margin-left: 2px;
                            font-weight: 600;
                        }

                        .up, .down {
                            text-align: center;
                            line-height: 16px;
                        }

                        .down {
                            transform: rotate(180deg);
                        }

                        .collapse {
                            margin-left: 4px;
                            font-size: 16px;
                            margin-top: -8px;
                            display: block;
                        }
                    }
                }

                .name {
                    font-size: 22px;
                    line-height: 28px;
                    font-weight: normal;
                    margin-left: 10px;
                }

                .field__name {
                    margin-right: auto;
                    max-width: 450px;
                    overflow: hidden;

                    @include media-breakpoint-down(lg) {
                        max-width: 200px;
                    }

                    @include media-breakpoint-down(xs) {
                        max-width: 260px;
                        margin-bottom: 10px;
                    }
                }
            }

            .subheader__sortNumber {
                font-size: 22px;
                line-height: 28px;
                font-weight: 400;
                margin-left: 10px;
            }

            .subheader__sortText {
                font-size: 17px;
                line-height: 28px;
                font-weight: 400;
                margin-right: 10px;
            }
        }
    }

    &__content__top {
        margin-bottom: 20px;
        text-align: right;
    }

    &__actions {
        border-top: 1px solid #ccc;
        margin-bottom: 100px;
        padding: 15px 0;

        .left {
            display: flex;
            flex-direction: row;
        }

        .right {
            float: right;
            margin-left: auto;
        }
    }

    &__actions-two {
        border-top: 1px solid #ccc;
        margin-bottom: 100px;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
    }

    &__actions-showtempl {
        border-top: none;
        margin-bottom: 100px;
        margin-top: 30px;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
    }

    .subheader-overwritable {
        margin-bottom: 10px;
        text-align: right;
    }

    .radiobtn-text {
        margin-right: 20px;
        display: inline-block;
    }

    .radiobtn-td {
        label {
            padding: 0 8px 0 2px;
            margin-bottom: 0;
            width: auto;
            cursor: pointer;
        }
    }

    input[type="radio"] {
        opacity: 0;
        position: relative;
        cursor: pointer;
        width: 18px;
        height: 18px;
    }

    input[type="radio"] + span.radiobtn-custom {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: transparent;
        border: 1px solid #004C82;
        margin: 0 0 0 -18px;
        border-radius: 50%;
        pointer-events: none;
    }

    input[type="radio"] + span.radiobtn-custom::after {
        content: '.';
        color: transparent;
        position: absolute;
        display: block;
        width: 2px;
        height: 2px;
        margin: 7px 0 0 7px;
        opacity: 0.6;
        border-radius: 50%;
    }

    input[type="radio"]:checked + span.radiobtn-custom::after {
        width: 10px;
        height: 10px;
        margin: 3px 0 0 3px;
        opacity: 1;
        background-color: #0078B2;
    }

    &--generate {
        //overwrite javascript setting width
        /*.hDropdown + .select2-container {
            width: 100% !important;
        }

        .select2-selection--multiple {
            width: 100%;
        }*/
        .multiselect-btns {
            max-width: 350px;
        }

        .reportgenerator__header {
            margin-bottom: 30px;
        }

        input[type="text"] {
            width: 100%;

            &.dateField,
            &.hasDatepicker {
                width: 150px;
            }
        }

        .table label {
            width: 90%;

            &.c-checkbox__styling,
            &.radiobtn-custom__styling {
                width: auto;
            }
        }

        .table__td-radio-first {
            width: 25%;
        }

        .vertical-top {
            vertical-align: top;
        }

        .c-checkbox__toggle {
            line-height: 1.2em;
        }

        .checkbox__container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .toggleField {
                font-weight: 700;
                margin-bottom: 5px;
            }

            .c-checkbox {
                margin: 4px 0px 4px;
                width: 50%;

                &__styling {
                    padding-left: 22px;
                    margin-bottom: 0;

                    &:before {
                        top: 3px;
                    }
                }

                &.margin-left {
                    margin-left: 25px;
                }

                &__number {
                    position: absolute;
                    left: -27px;
                    top: 0;
                    text-align: right;
                    width: 22px;
                }
            }

            .checkbox__column {
                max-width: 50%;
                flex-basis: 50%;

                .c-checkbox {
                    width: auto;
                    display: block;
                }
            }

            input[type="checkbox"]:checked + input + label::after,
            input[type="checkbox"]:checked + label::after {
                top: 8px;
            }
        }
    }

    &--schedule {

        .table {

            .darkBg {
                background-color: rgba(235,239,242,.5);
            }

            .vertical-top {
                vertical-align: top;
            }

            .has-error {
                border: 1px solid red;
            }
        }

        .radiobtn-custom {
            position: relative;
            top: 3px;
            margin-right: 5px;
        }

        .select2 {
            min-width: 120px;
            margin: 0;
            margin-right: 5px;

            &.select2-container--disabled .select2-selection__rendered {
                color: #ccc;
            }
        }

        input[disabled] {
            color: #ccc;
            border-color: $text-6;
        }

        input[type="number"] {
            margin-right: 5px;
        }

        input[type=text].table__time {
            width: 90px;
        }

        .input-sm {
            width: 50px;
        }

        .padding-right-md {
            margin-right: 20px;
        }

        .flex {
            display: flex;
            justify-content: space-between;
        }
    }
}
