.footer {
    min-height: 315px;
    color: $text-2;
    background: url(../images/footer-bg-1920.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    font-weight: 600;
    line-height: 30px;

    &--notloggedin {
        background: $nissens-blue;
        min-height: initial;

        .footer__logo, .footer__logo--none {
            margin: 12px;
        }
    }

    @include media-breakpoint-down(md) {
        background-position: 50%;
    }

    a {
        color: $text-2;
        font-weight: 600;
        font-size: 18px;
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__link {
        text-align: center;
        margin: 0 20px;
    }

    &__logo {
        height: 70px;
        text-align: center;
        margin: 60px auto 30px;
        background-image: url('../../wwwroot/images/logo_white.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        &--top {
            margin: 20px auto;
        }

        &--none{
            background-image: none;
        }
    }

    &__address {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    &__contact {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-bottom: 50px;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        span {
            margin: 0 4px;
        }

        a {
            text-decoration: underline;
        }
    }
}
