.c-checkbox {
    position: relative;
    display: inline-block;
    margin: 8px;
    cursor: pointer;

    &.nomarginleft{
        margin-left: 0;
    }

    input[type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
        margin: 0;
        position: absolute;
        left: 0px;
        top: 2px;
    }

    &__text {
        font-weight: 600;
        color: $nissens-blue;

        &.smaller {
            font-size: 16px;
        }
    }

    &__inbtn {
        padding-left: 4px;
        padding-right: 80px;
        padding-bottom: 8px;
        padding-top: 10px;
        z-index: 4;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            margin: auto;
        }

        &::before {
            width: 18px;
            height: 18px;
            border-radius: 4px;
            border: 1px solid $nissens-blue;
            cursor: inherit;
            margin: 6px 0 0 0;
            background-color: #fff;
        }
    }

    &__styling {
        padding-left: 25px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            margin: auto;
        }

        &::before {
            width: 18px;
            height: 18px;
            left: 0px;
            border-radius: 4px;
            border: 1px solid $nissens-blue;
            cursor: inherit;
            margin: 0;
        }

        &:disabled{
            border: 1px solid #ccc;
        }
    }
    // The check-icon
    input[type="checkbox"]:checked + input + label::after,
    input[type="checkbox"]:checked + label::after {
        opacity: 1;
        width: 9px;
        height: 5px;
        top: 5px;
        left: 5px;
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
    }

    input[type="checkbox"]:checked + input + label::before,
    input[type="checkbox"]:checked + label::before {
        background-color: $nissens-blue;
    }

    input[type="checkbox"]:disabled + input + label::before,
    input[type="checkbox"]:disabled + label::before {
        background-color: #ccc;
    }
}

.switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 106px;
    height: 32px;
    padding: 3px;
    background-color: white;
    border-radius: 18px;
    cursor: pointer;
    margin-top: -.2em;
}

.switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 18px;
    font-weight: 600;
    background: #004A80;
    border-radius: inherit;
    transition: 0.15s ease-out;
    transition-property: opacity background;
}

.switch-label:before, .switch-label:after {
    position: absolute;
    top: 48%;
    margin-top: -.5em;
    line-height: 1;
    transition: inherit;
    color: white;
}

.switch-label:before {
    content: attr(data-off);
    right: 12px;
}

.switch-label:after {
    content: attr(data-on);
    left: 12px;
    opacity: 0;
}

.switch-input:checked ~ .switch-label {
    background-color: #00AEEF;
}

.switch-input:checked ~ .switch-label:before {
    opacity: 0;
}

.switch-input:checked ~ .switch-label:after {
    opacity: 1;
}

.switch-handle {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 52px;
    height: 28px;
    background: white;
    border-radius: 18px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.5);
    transition: left 0.15s ease-out;
}

.switch-input:checked ~ .switch-handle {
    left: 49px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-green > .switch-input:checked ~ .switch-label {
    background: #4fb845;
}
