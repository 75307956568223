
$padding-default: 9px 20px;
$padding-small: 6px 14px;
$font-size-default: 17px;
$font-size-small: 15px;

.btn {
    font-weight: 600;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    border: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        padding: $padding-default;
        cursor: default;
    }

    &--collapse {
        display: block;
        background: transparent;
        width: 16px;
        height: 16px;
        padding: 0;
    }

    &--collapse[aria-expanded="true"] {
        background-image: url(../images/minus.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    &--collapse[aria-expanded="false"] {
        background-image: url(../images/plus.svg);
        background-repeat: no-repeat;
        background-position: center;
    }

    &--default {
        //blue
        background: linear-gradient(180deg, #02AFEF 0%, #00A3DF 100%);
        box-shadow: 0 2px 4px 0 rgba(0,76,130,0.2);
        padding: $padding-default;
        font-size: $font-size-default;
        color: $text-2;

        &:hover {
            color: $text-2;
        }

        &:focus {
            color: $text-2;
        }

        &.compareactive {
            background: linear-gradient(180deg, #005B93 0%, #004A80 100%);
        }

        &.linkList--item {
            display: inline-block;
            margin-bottom: 5px;
            color: #fff;
            text-decoration: none;
            font-size: 14px;
        }

        &.login {
            padding: 9px 0;
        }
    }

    &--inactive {
        background: linear-gradient(180deg, #aaa 0%, #ccc 100%);
        color: #807d7d;
        box-shadow: 0 2px 4px 0 #999;
        padding: $padding-default;
        font-size: $font-size-default;

        &:hover {
            color: #807d7d;
            cursor: default;
            text-decoration: none;
        }

        &:visited {
            color: #807d7d;
            cursor: default;
            text-decoration: none;
        }
    }

    &--file {
        background: #FFFFFF;
        padding: $padding-small;
        font-size: $font-size-small;
        color: $text-3;
    }

    &--right {
        margin-left: auto;
    }

    &--dateclear {
        background: linear-gradient(180deg, #02AFEF 0%, #00A3DF 100%);
        padding: $padding-small;
        font-size: $font-size-small;
        color: $text-2;

        &:hover, &:active, &:focus {
            color: $text-2;
            text-decoration: none;
        }
    }

    &--small {
        background: linear-gradient(180deg, #02AFEF 0%, #00A3DF 100%);
        padding: $padding-small;
        font-size: $font-size-small;
        color: $text-2;

        &:hover, &:active, &:focus {
            color: $text-2;
            text-decoration: none;
        }
    }

    &--search {
        background: linear-gradient(180deg, #02AFEF 0%, #00A3DF 100%);
        box-shadow: 0 2px 4px 0 rgba(0,76,130,0.2);
        padding: $padding-default;
        font-size: $font-size-default;
        color: $text-2;

        &:hover {
            color: $text-2;
        }
    }

    &--selectedProduct {
        background: linear-gradient(180deg, #666 0%, #444 100%);
        box-shadow: 0 2px 4px 0 rgba(0,160,0,0.2);
        padding: 9px 14px;
        font-size: $font-size-default;
        font-weight: bold;
        color: $text-2;

        &:hover {
            color: $text-2;
        }

        &:focus {
            color: $text-2;
        }
    }

    &--addToBasket {
        //dark blue
        /*background: linear-gradient(180deg, #00BF2A 0%, #00A000 100%);
        box-shadow: 0 2px 4px 0 rgba(0,160,0,0.2);*/
        /*background: linear-gradient(180deg, #02AFEF 0%, #00A3DF 100%);
        box-shadow: 0 2px 4px 0 rgba(0,76,130,0.2);*/
        background: linear-gradient(0deg, #004068 0%, #004A80 100%);
        box-shadow: 0 2px 4px 0 rgba(0,55,95,0.4);
        padding: 9px 14px;
        font-size: $font-size-default;
        color: $text-2;
        float: right;

        &:hover {
            color: $text-2;
        }

        &:focus {
            color: $text-2;
        }

        span {
            padding: 0 3px;
        }

        svg {
            margin-bottom: -2px;
        }
    }

    &--paging {
        padding: 4px;
        background: #fff;
        color: $text-5;
        border: 1px solid $light-grey;

        &.active {
            background: $nissens-blue;
            color: $text-2;
            border: 0px;
        }
    }

    &--white {
        border: 1px solid #9b9b9b;
        background: #fff;
        color: #9b9b9b;
        padding: $padding-default;
        font-size: $font-size-default;
        text-decoration: none;

        &:hover {
            color: #444 !important;
            text-decoration: none;
        }

        &:focus {
            color: #444 !important;
            text-decoration: none;
        }
    }

    &--black {
        background: linear-gradient(0deg, #000 0%, #3D3D3D 100%);
        padding: $padding-default;
        font-size: $font-size-default;
        text-decoration: none;
        color: #fff;

        &:hover {
            color: #ccc;
            text-decoration: none;
        }

        &:focus {
            color: #ccc;
            text-decoration: none;
        }
    }

    &--higher {
        margin-top: -5px;
    }
}
