﻿.c-login {
    background: #fff;
    box-shadow: 0 2px 34px 0 rgba(0,0,0,0.5);
    padding: 0 30px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    max-width: 415px;

    @include media-breakpoint-down(lg) {
        padding: 0;
    }

    &__terms {
        margin-bottom: 10px;
        margin-top: 8px;

        a {
            text-decoration: underline;
        }
    }

    &__header {
        font-size: 36px;
        line-height: 45px;
        text-align: center;
        padding: 12px;

        @include media-breakpoint-down(lg) {
            padding: 10px;
        }
    }

    &__headbar {
        display: flex;
        width: 100%;
        max-width: 355px;
        padding: 15px 30px 12px;
        background-color: #F5F7F8;

        .hDropdown {
        }
    }

    &__dropdown {
        display: none;
    }

    &__language {
        float: none;
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        background-color: white;
        padding-left: 16px;
        min-height: 36px;

        &-link {
            width: 100%;
            display: block;
            background-repeat: no-repeat;
            background-position: right 8px center;
            text-decoration: none;

            &:hover, &:active, &:focus, &:visited {
                text-decoration: none;
                color: #444;
            }
        }

        .show {
            & + .language__container {
                display: block;
            }
        }

        .language__container {
            width: 100%;
            display: none;
        }

        .languageselector__list {
            width: 285px;
            margin-top: 4px;
            border-top: 1px solid #ccc;
        }

        .languageselector__link {
            height: 30px;
            padding: 2px 6px 0;
        }
    }

    &__footer {
        display: flex;
        width: 100%;
        max-width: 355px;
        padding: 15px 30px;
        background-color: #E1E5E7;

        @include media-breakpoint-down(lg) {
            padding: 12px 30px;
        }

        p {
            margin-bottom: 0;
        }
    }

    &__loginbox {
        display: flex;
        width: 100%;
        max-width: 355px;
        padding: 20px 30px 15px;

        @include media-breakpoint-down(lg) {
            padding: 15px 30px 10px;
        }

        label {
            margin-bottom: 5px;
        }

        .c-checkbox {
            display: initial;
            margin: 0;

            &__text {
                color: $text-8;
                font-weight: normal;
            }
        }

        .btn {
            width: 100%;
        }

        &--submit {
            margin: 15px -5px;
            display: flex;
            align-items: baseline;

            @include media-breakpoint-down(lg) {
                margin: 15px -5px;
            }

            svg {
                transform: rotate(-90deg);
                margin-right: 5px;
            }

            .goback {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                line-height: 13px;
            }
        }

        &--createforgot {
            text-align: center;

            a {
                text-decoration: underline;
            }
        }
    }
}
