
.c-accept-cookie {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;   
    height: 45px;
    width: 100%;
    z-index: 6000;   
    opacity: 0.8;
    transition: height 0.5s;

    &.is-accepted {
        height: 0;   
        bottom: 0; 
    }       

    &__inner {
        line-height: 17px;
        font-size: 16px;
        font-weight: 300;
        position: absolute;   
        right: 0;
        left: 0;
        margin: 0 50px; 
        background: #000000;
        color: #fff;
        height: inherit;

        @include media-breakpoint-down(lg) {   
            margin: 0 10px;
        }
    }

    &__content {
        padding: 14px 45px 12px 15px;

        @include media-breakpoint-only(xs) {     
            padding: 6px 45px 0px 15px;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    &__text {
        
       @include media-breakpoint-only(xs) {         
            width: 90%;
            display: block;
            height: 17px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
       }
    }

    &__close {
        position: absolute;
        cursor: pointer;
        top: 3px;
        right: 5px;
        z-index: 1;
        padding: 10px 10px;
        font-size: 14px;
        line-height: 15px;
        background: none;
        border: none;
    }

  }