
a {
    color: $text-1;
    font-size: 16px;
}

.show-more {
    font-size: 16px;
    color: $text-3;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.header-link {
    font-size: 15px;
    color: $text-1;
    text-decoration: none;
}

.header-basket {
    font-size: 18px;
    text-decoration: none;

    &--empty {
        color: $text-5;
    }

    &--has-items{
        color: $basket-hasitems;
    }
}

.resultLink {
    //class already exist from old design
    text-decoration: none;

    &:hover {
        text-decoration: none;
        color: white;
    }
}

.hoverImage {
    display: block;
    width: 100%;
}