﻿.hDropdown {

    & + .select2-container {
        margin: 0 0 14px;
    }


    .select2-selection--multiple {
        height: auto !important;
    }

    & + .select2-container .select2-selection,
    &__select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        line-height: 35px;
        border: 1px solid $light-grey;
        border-radius: 4px;
        background-color: #FFFFFF;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;

        &:focus {
            outline: none;
        }

        &__arrow,
        &-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 100%;
            background: none;
            border: 0;

            b {
                display: block;
                position: relative;
                top: 0;
                left: 0;
                margin: 0;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gIDxwYXRoIGQ9Ik0xMiAwTDYuNSA1LjUgMSAwIiBzdHJva2U9IiM1NTUiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+);
                background-repeat: no-repeat;
                border: 0;
                width: 12px;
                height: 6px;
                transform: rotate(0deg);
                transition: transform 250ms ease-in-out;
            }
        }

        &[aria-expanded="true"] b {
            transform: rotate(180deg);
        }

        &__rendered {
            .select2-search {
                padding: 0;
                width: 100%;

                &__field {
                    margin: 0;
                }
            }
        }

        &--disabled {
            &:after {
                display: block;
                content: '';
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(100,100,100,0.3);
            }
        }

        &--multiple {
            height: auto;
            display: block;         
            width: 350px;          
            padding-left: 0;

            @include media-breakpoint-down(lg) {
                width: 180px;
            }

            .select2-selection__choice {
                background-color: #E1E5E7;
                color: #000;
                border: none;
                border-radius: 6px;
                cursor: default;
                float: left;
                margin-right: 5px;
                margin-top: 5px;
                padding: 0 12px 0 8px;
                font-size: 15px;
                line-height: 24px;
                font-weight: 600;
            }

            .select2-selection__choice__remove {
                color: #000;
                margin-right: 8px;
                font-size: 18px;
            }
        }
    }
    // Height = 50px
    &--50 + .select2.select2-container {
        .select2-selection {
            height: 50px;
        }
    }
    // Height = 30px
    &--30 + .select2.select2-container {
        margin: 0;

        .select2-selection {
            height: 30px;
        }
    }

    &__select {
        padding: 0 0 0 8px;
        cursor: pointer;

        &--30 {
            height: 30px;
        }

        &--50 {
            height: 50px;
        }

        &-arrow {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: auto;
            min-width: 0;
        }
    }

    &__selected-item {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
    }

    &__menu {
        display: flex;
        flex-flow: column;
        box-shadow: none;
        border-radius: 0;
        border-top: transparent;
        transition: none;
        padding: 0;

        .buybox & {
            max-height: 300px;
            overflow: auto;
        }
    }

    &__item {
        float: none;
        padding: 0 40px 0 8px;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
            padding: 0 8px;
        }

        &:hover,
        &.selected {
            background-color: #5897FB;
            cursor: pointer;
        }

        &:first-child {
            border-top: 0;
        }

        .buybox & {
            display: inline-block;
            font-size: 13px;
            line-height: 20px;
            padding: 4px 8px;
        }
    }

    [data-type="singleOrdering"] & {
        max-width: 220px;
    }

    [data-js='buyboxEmployeeTable'] & {
        width: 100%;
    }
}

.select2-dropdown {
    border: 1px solid $light-grey;
    border-radius: 4px;
    z-index: 9001;
    font-size: 16px;
    line-height: 16px;

    &--above {
        //  border-color: $h-lines-dark $h-lines-dark transparent;
    }

    &--below {
        // border-color: transparent $h-lines-dark $h-lines-dark;
    }
}

.select2-search {
    &__field {
        outline: none;
        transition: box-shadow 250ms ease-in-out;

        &:focus {
            //   @include inset-hover-boxshadow();
        }
    }
}
