﻿.productdetails {

    &__addedtobasket {
        display: none;
        align-items: center;
        justify-content: center;
        height: 164px;
        background-color: $add-to-basket-msg;
        color: #fff;
        width: 30%;
        z-index: 9001;
        position: fixed;
        padding: 0px;
        margin: 0 0 0 -15%;
        left: 50%;
        top: 30%;
        text-align: center;
        box-shadow: 0 2px 14px 0 rgba(0,0,0,0.3), 0 12px 44px 0 rgba(0,55,95,0.3);
        font-size: 36px;
        line-height: 45px;

        @include media-breakpoint-down(lg) {
            font-size: 30px;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
            margin: 0;
            left: 0;
            font-size: 24px;
        }

        .icon {
            width: 45px;
            height: 45px;
        }

        .message {
            margin-left: 14px;
        }
    }


    &__header {
        border-bottom: 1px solid #CCC;
        display: inline-block;
        font-size: 16px;
        padding: 9px 0;

        &__main {
            font-size: 36px;
            display: block;
            line-height: 45px;

            @include media-breakpoint-down(md) {
                padding: 0 0 0 10px;
                text-align: center;
            }
        }

        &__sub {
            padding-left: 2px;
            line-height: 20px;
            display: flex;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                padding: 0 10px;
                justify-content: center;
            }
        }

        &__pdf {
            display: flex;

            @include media-breakpoint-down(md) {
                display: none;
            }

            a {
                text-decoration: underline;
            }

            .printpdf {
            }

            .sendpdf {
                margin-right: 40px;
            }

            .printpdf, .sendpdf {
                .icon {
                    display: inline-block;
                    height: 25px;
                    float: left;
                }

                div:not(.icon) {
                    padding-left: 25px;
                }
            }
        }
    }

    &__content {
        padding: 30px 0;
    }

    &__basket {
        padding-left: 10px;

        @include media-breakpoint-down(md) {
            padding-left: 0;
        }

        &__quantity {
            display: flex;
            align-items: center;

            input {
                width: 96px;
                font-size: 30px !important;
                text-align: right;
            }

            &__label {
                font-size: 30px;
                line-height: 38px;
                margin: 0 0 20px 6px;
            }
        }

        &__price {
            background-color: #F7F7F7;
            padding: 18px 18px 10px;
            margin-bottom: 4px;

            &__total {
                font-size: 36px;
                line-height: 45px;
                text-align: right;
                margin-top: 6px;
            }

            &__single {
                font-size: 16px;
                line-height: 20px;
                text-align: right;
            }

            &__discountblock {
                .price {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        &__locations {

            .location {
                display: flex;
                background-color: #F7F7F7;
                padding: 18px;
                margin-bottom: 4px;
                font-size: 18px;
                font-weight: 600;
                line-height: 23px;
                justify-content: space-between;

                &__btn {
                    justify-content: center;
                    flex-wrap: wrap;
                    padding: 12px 18px;

                    .location__stockandname {
                        padding: 5px;
                    }

                    span {
                        font-weight: normal;
                    }
                }

                &__stockandname {
                    display: flex;
                    align-items: center;
                    flex: 1 1 auto;

                    .no1, .no2, .no3, .no4 {
                        min-width: 18px;
                        height: 18px;
                        margin-right: 10px;
                        display: inline-block;
                    }

                    .no3 {
                        &.red {
                            background: $gradient-3;
                        }

                        &.green {
                            background: $gradient-1;
                        }

                        &.yellow {
                            background: $gradient-2;
                        }
                    }

                    .no1 {
                        //notinstock
                        background: $gradient-3;
                    }

                    .no2 {
                        //instock
                        background: $gradient-1;
                    }

                    .no4 {
                        //partiallyinstock
                        background: $gradient-2;
                    }
                }
            }
        }
    }

    &__images {
        padding-right: 10px;

        @include media-breakpoint-down(lg) {
            padding-right: 0;
        }

        .icon360 {
            width: 40px;
            height: 38px;
            background-image: url(../images/icon-360.png);
            position: absolute;
            margin: 10px;
        }

        &__icons {
            text-decoration: underline;

            @include media-breakpoint-down(md) {
                text-align: center;
            }

            span {
                margin-left: 5px;
            }

            &__text {
                font-size: 13px;
            }

            a {
                &:hover {
                    text-decoration: none !important;
                }

                span {
                    text-decoration: underline;
                }
            }
        }

        &__clicktext {
            text-align: center;
            margin: 15px 0 30px;

            a {
                text-decoration: underline;
            }
        }

        &__images {
            padding: 30px 15px 30px 0;

            @include media-breakpoint-down(md) {
                padding: 15px;
                text-align: center;
            }

            .ImageContainer {
                display: flex;
                justify-content: center;
            }

            .MainImage {
                width: 100%;
            }

            .DefaultImage {
                width: 100%;
            }

            .thumbnail {
                max-width: 100px;
                padding: 8px;
            }

            .hoverImage360 {
                display: none;
                width: 100%;


                .autoplayContainer {
                    display: none;
                }
            }
        }

        .zoomPad {
            @include media-breakpoint-down(md) {
                .zoomPup, .zoomPreload, .zoomWindow {
                    display: none !important;
                }
            }
        }

        .thumbNailContainer {
            a {
                &:hover {
                    text-decoration: none !important;
                }
            }
        }
    }

    &__info {
        padding: 0 15px;

        @include media-breakpoint-down(lg) {
            padding: 0;
        }

        &__header {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            padding: 8px 0;

            @include media-breakpoint-down(md) {
                padding: 8px 20px;
            }
        }

        &__info {
            border: 1px solid #ccc;
            padding: 2px 24px;
            background-color: #F7F7F7;
            font-size: 14px;

            ul {
                list-style-type: none;
                padding: 0;
                margin-bottom: 2px;
            }

            li {
                font-size: 14px;
                padding: 4px 0;
                border-bottom: 1px solid #ccc;
                display: flex;

                &.hasnote {
                    flex-direction: column;
                }

                &:last-of-type {
                    border-bottom: none;
                }

                .type {
                    font-weight: 600;
                    width: 50%;
                }

                .value {
                    width: 50%;
                }

                .typevalue_wrap {
                    display: flex;
                }

                .typevalue_note {
                    display: block;
                    font-size: 13px;
                    margin: 4px 0;
                    line-height: normal;
                    font-weight: 600;
                    font-style: italic;

                    a {
                        font-size: 13px !important;
                        text-decoration: underline;
                    }
                }
            }

            .note__headline {
                font-weight: bold;
                display: block;
            }

            .note {
                font-size: 14px;
            }
        }

        &__allcars {
            ul {
                list-style-type: none;
                padding: 0;
            }

            li {
                font-size: 14px;
                padding: 5px 0;
                border-bottom: 1px solid #ccc;
                display: flex;

                &:last-of-type {
                    border-bottom: none;
                }

                .type {
                    font-weight: 600;
                    width: 50%;
                }

                .value {
                    width: 50%;
                }
            }
        }

        &__accordion {
            border: 1px solid #ccc;
            padding: 10px 24px;
            margin: 10px 0;
            font-size: 14px;

            @include media-breakpoint-down(md) {
                margin: 10px;
            }

            .fake-li {
                font-size: 14px;
                padding: 5px 0;
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                .type {
                    font-weight: 600;
                    width: 50%;
                }

                &.open {
                    .fake-li--arrow {
                        transform: rotate(180deg);
                        transition: all linear .2s;
                    }
                }

                &.closed {
                    .fake-li--arrow {
                        transition: all linear .2s;
                    }
                }
            }
        }
    }

    &__notfound {
        margin: 30px 0;
    }
}


.pswp__top-bar {
    opacity: 1 !important;

    @include media-breakpoint-down(lg) {
        opacity: 1 !important;
    }
}

/*.pswp__zoom-wrap {
    transform: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
}*/

.pswp__img {
    position: relative !important;
}

.pswp__counter {
    display: inline-block;
}

/*.pswp__caption {
    top: 0 !important;
}*/

.pswp__caption__center {
    text-align: center;
    font-size: 28px;
    color: #fff;
}

.pswp__item {
    .image_container {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

.pswp__img--placeholder--blank {
    display: none;
}

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
    /* Serve SVG sprite if browser supports SVG and resolution is more than 105dpi */
    .pswp--svg .pswp__button,
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
        background-image: url(../images/default-skin.png);
    }

    .pswp--svg .pswp__button--arrow--left,
    .pswp--svg .pswp__button--arrow--right {
        background: none;
    }
}
