.cmsToolbar {
    display: inline-block;
    border-top: 1px solid #cacaca;
    padding: 10px 3px 10px 3px;
    background-color: #fcfcfc;
    position: fixed;
    bottom: 0;
    z-index: 1;
    right: 0;
    left: 0;
    text-align: right;
}

@media (max-width: 544px) {
    .cmsToolbar {
        display: none;
    }
}

.cmsToolbar a {
    color: Black;
}

.cmsActions {
    border-top: 1px solid #cacaca;
    margin-top: 15px;
    padding-top: 5px;
    text-align: right;
}

.cmsBigField {
    width: 600px;
    height: 300px;
}

.cmsNotSavedBox {
    position: fixed;
    background-color: #fefefe;
    bottom: 50px;
    color: #000;
    display: none;
    font-weight: 700;
    font-size: 100%;
    left: 80%;
    width: 20%;
    text-align: center;
    z-index: 12;
    padding: 20px;
    border-top: 1px solid #cacaca;
    border-left: 1px solid #cacaca;
}

.cmsEdit {
    position: absolute;
    width: 24px;
    height: 24px;
    display: block;
    margin-left: -24px;
    background-image: url(../images/pen.gif);
}

.cmsAdd {
    margin-top: 10px;
}

.cmsHand {
    position: absolute;
    width: 24px;
    height: 24px;
    display: block;
    margin-left: -24px;
    background-image: url(../images/hand.png);
    margin-top: 30px;
}

.cmsInputList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.cmsInputList li {
    margin: 0;
    padding: 0;
}

.cmsAddWidgetDialog table tr td {
    vertical-align: middle;
    border: 1px solid #cacaca;
    padding: 15px;
}

.cmsAddWidgetDialog table tr td img {
    margin: -10px;
}

a.cmsAddWidget {
    display: block !important;
    margin-top: 40px !important;
    width: 150px;
}

.subpagelist {
    list-style-type: none;
    border: 1px solid #CCCCCC;
    color: #1C94C4;
    font-weight: bold;
    outline: medium none;
    margin: 5px;
    padding: 5px;
    width: 150px;
}


.cmsAddWidgetDialog table tr td a {
    color: Black;
}

.cmsButtonAdd {
    padding: 8px 8px 8px 35px !important;
    background-image: url(../images/add.png);
}

.cmsButtonRemove {
    padding: 8px 8px 8px 35px !important;
    background-image: url(../images/remove.gif);
}

.cmsButtonOK {
    padding: 8px 8px 8px 35px !important;
    background-image: url(../images/ok.gif);
}

.cmsButtonPen {
    padding: 8px 8px 8px 35px !important;
    background-image: url(../images/pen.gif);
}

.cmsButton {
    display: inline !important;
    padding: 8px 8px 8px 8px;
    border: 1px solid #cbcbcb;
    background-color: #fafafa;
    background-repeat: no-repeat;
    background-position: 3px 4px;
    cursor: pointer;
    color: #333399 !important;
    text-decoration: none !important;
}

.cmsButton:hover {
    text-decoration: underline !important;
}

.cmsDialogHeader {
    border-bottom: 1px solid #cacaca;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.cmsDialogHeader p {
    margin: 0;
    padding: 0;
    width: 400px;
}

.cmsDialogHeader h3 {
    margin: 0;
    padding: 0;
    color: #0066FF;
}

.cmsDialog input[type=text] {
    width: 380px;
}

.cmsFilePickerTable {
    width: 398px;
    height: 398px;
    border: 1px solid gray;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    display: block;
}

.cmsFilePickerTable tbody, .cmsFilePickerTable tr {
    width: 398px;
    height: 398px;
    vertical-align: middle;
    text-align: center;
}

.cmsFilePickerTable tr td {
    vertical-align: middle;
    text-align: center;
    empty-cells: show;
    width: 398px;
    height: 398px;
}


.cmsFilePickerTable.cmsVideo img {
    display: none;
}

.cmsFilePickerTable.cmsVideo {
    background-image: url(../images/chooseimage.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.cmsFilePickerTable.cmsVideo.selected {
    background-image: url(../images/video.png);
}

.cmsContainerContent {
    .image {
        display: flex;

        img {
            width: 100%;
            height: 100%;
        }
    }
}
