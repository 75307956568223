﻿.quickbasket {
    text-align: right;

    &__link {
        font-size: 16px;
        color: $text-5;

        @include media-breakpoint-down(lg) {
            font-size: 14px;
        }

        @include media-breakpoint-down(md) {
            font-size: 12px;
            display: flex;
            justify-content: flex-end;
        }
    }

    &__icon {
        margin-top: 2px;
        display: inline-block;
        float: right;
        position: relative;
        margin-left: 5px;

        @include media-breakpoint-down(lg) {
            margin-top: 4px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 9px;
            margin-left: 10px;
        }

        @include media-breakpoint-down(xs) {
            display: none;
        }


        g {
            fill: $text-5;
        }
    }

    &__total {
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            flex-direction: column;

            .text {
                display: flex;
                flex-direction: column;
            }
        }

        .freightLine {
            color: black;
        }
    }

    &--iteminbasket {
        .quickbasket__link {
            color: $basket-hasitems !important;
        }

        .quickbasket__icon g {
            fill: $basket-hasitems;
        }
    }

    @include media-breakpoint-down(lg) {
        margin-top: 10px;
    }

    @include media-breakpoint-down(md) {
        margin-top: 12px;
        margin-right: 60px;
    }

    @include media-breakpoint-down(xs) {
        margin-top: 10px;
        margin-right: 50px;
    }
}
