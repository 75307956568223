﻿
.table-striped {
    tbody {
        tr {
            &:nth-of-type(odd) {
                background-color: rgba(235,239,242,0.5);

                &.active {
                    background-color: $odd-blue;
                }
            }

            &.active {
                background-color: $odd-blue;
            }
        }
    }
}

.registrationtable {
    display: table;
}

.resulttable {
    overflow-x: auto;
    color: $table-text;
    font-size: 14px;
    position: relative;

    &--core {
        display: table;
    }

    td {
        white-space: nowrap;
        border: 1px solid #EBEFF2;
        border-bottom: 0;
        border-top: 0;
        vertical-align: middle;
        padding: .70rem;

        @include media-breakpoint-down(lg) {
            border: 0;
        }

        &.resulttable__topheader {
            border: 0;
        }
    }

    th {
        white-space: initial;
        max-width: 100px;
        overflow-wrap: break-word;

        &.wider {
            max-width: 112px;
        }
    }

    &--nomaxtd {
        th {
            max-width: none;
        }
    }

    tbody tr td:first-child {
        border-left: 0;
    }

    tbody tr td:last-child {
        border-right: 0;
    }

    &__topheader {
        color: white;
        font-size: 22px;
        font-weight: 600;
        text-align: center;

        @include media-breakpoint-down(lg) {
            text-align: left;
            font-size: 18px;
        }

        &--left {
            background-color: $table-header1;
        }

        &--right {
            background: $table-header2;
        }
    }

    &__subheader {
        font-weight: bold;
        line-height: 19px;
        padding: 6px;
        border-bottom: 1px solid #ccc;

        th {
            vertical-align: top !important;
        }
    }

    &__wrapper {
        display: block;
        width: 100%;
        overflow: inherit;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive {
        display: table;
    }

    .productlinks {
        text-align: center;
        padding: 2px 10px;

        div {
            margin: 8px 0;

            &.inactive {
                .btn {
                    border: 1px solid #9B9B9B;
                    background: #fff;
                    color: #9B9B9B;
                }
            }

            &.c-checkbox {
                display: none;
                margin: 0;
                width: 0;
                padding: 0;
                margin-top: 4px;
                // The check-icon
                input[type="checkbox"]:checked + input + label::after,
                input[type="checkbox"]:checked + label::after {
                    opacity: 1;
                    width: 9px;
                    height: 5px;
                    top: 11px;
                    left: 8px;
                    border: 2px solid $nissens-blue;
                    border-top: none;
                    border-right: none;
                    transform: rotate(-45deg);
                }

                input[type="checkbox"]:checked + input + label::before,
                input[type="checkbox"]:checked + label::before {
                    background-color: #fff;
                }

                &.show {
                    display: block;

                    & + .btn {
                        padding-left: 30px;
                        min-width: 80px;
                        display: flex;
                    }
                }
            }
        }

        .btn--default {
            padding: 9px 0px;
            width: 60px;
            font-size: 14px;
        }

        .btn--selectedProduct {
            padding: 9px 0px;
            width: 60px;
            font-size: 14px;
        }

        a {
            &:focus, &.marked {
                text-decoration: none;
                color: #fff;
            }
        }
    }

    .OrderType10 {
        background: url(../images/Graphics.png) 0px 0px no-repeat;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
    }

    .OrderType20 {
        background: url(../images/Graphics.png) 0px -40px no-repeat;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
    }

    .OrderType30 {
        background: url(../images/Graphics.png) 0px -20px no-repeat;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
    }

    .packlist {
        background: url(../images/Graphics.png) 0px -80px no-repeat;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        display: block;
        float: left;
        margin-right: 7px;
        cursor: pointer;
    }

    .confirmation {
        background: url(../images/Graphics.png) 0px -60px no-repeat;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        display: block;
        float: left;
        margin-right: 9px;
        cursor: pointer;
    }

    .invoices {
        background: url(../images/Graphics.png) 0px -99px no-repeat;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        display: block;
        float: left;
        cursor: pointer;
    }
}

.swipeAfter {
    content: '\02026';
    color: transparent;
    position: absolute;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
    z-index: 9;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    right: 0;
    width: 60px;
    top: 0px;
    opacity: 1;
    visibility: visible;

    @include media-breakpoint-down(lg) {
        top: 0px;
    }
}

.swipeBefore {
    content: '\02026';
    color: transparent;
    position: absolute;
    background: linear-gradient(-270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
    z-index: 9;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    width: 60px;
    left: -1px;
    opacity: 0;
    visibility: hidden;
}

.table-head-float {
    height: 121px;
    display: block;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 5px;
    width: calc(100% - 10px);
    background-color: white;
    display: none;

    tbody {
        visibility: hidden;
    }

    @include media-breakpoint-down(md) {
        top: 60px;
    }
}
