﻿.help-page {
    margin-top: 15px;

    h1 {
        font-size: 36px;
        font-weight: normal;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 28px;
        font-weight: normal;
        margin-bottom: 0;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 6px;
    }

    blockquote {
        font-size: 24px;
        font-weight: normal;
        font-style: italic;
        color: #004a80;
    }

    &__header {
        color: #fff;
        background-color: #004c82;
        border-radius: 10px;
        padding: 15px;
    }

    .tree {
        padding: 15px;
    }

    .topImage {
        overflow: hidden;
        margin: 5px;
        padding: 0;
    }

    &__content {
        margin: 35px 0;
        padding: 20px;
    }

    .btn {
        color: #004a80;
        background: transparent;
        padding: 0;
        text-decoration: underline;
        box-shadow: none;
    }
}
