﻿$base-line-height: 50px;
$white: rgb(255,255,255);
$black: rgb(0,0,0);
$off-white: rgba($white, 0.2);
$dark-gray: rgba($black, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loadContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.2);
    font-weight: bold;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    box-sizing: border-box;
    z-index: 2999;

    &--top {
        align-items: unset;
    }

    &--fixed {
        position: fixed;
        background-color: rgba(0,0,0,.7);
}
}

.modal__loader {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-weight: bold;
    top: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    box-sizing: border-box;
    z-index: 5000;

    &--top {
        align-items: unset;
    }
}

.loader, 
.modal__spinner, 
.spinner {
    border-radius: 50%;
    width: $base-line-height;
    height: $base-line-height;
    border: .5rem solid $off-white;
    border-top-color: $white;
    animation: spin $spin-duration infinite linear;
    margin: 0;

    .loadContainer--top > & {
        margin: 100px auto 0;
    }

    &--inverted {
        border: .5rem solid $dark-gray;
        border-top-color: $black;
    }

    &--fixed {
        position: fixed;
        top: 50%;
    }
}
