
// Font
@import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,400,400i,600,600i,900,900i';

// Vendor
@import "./autogen/cms/_variables.scss";
@import "vendor/_bootstrap-custom";
@import "../../bower_components/select2/src/scss/core";
@import "../../bower_components/photoswipe/dist/photoswipe.css";
@import "../../bower_components/photoswipe/dist/default-skin/default-skin.css";
@import "../../bower_components/webui-popover/dist/jquery.webui-popover.css";

// Modules
@import "modules/_colors";
@import '_global';
@import "modules/_buttons";
@import "modules/_stockstatus";
@import "modules/_forms";
@import "modules/_hDropdown";
@import "modules/_links";
@import "modules/_svg";
@import "modules/_checkbox";
@import "modules/_radiobutton";
@import "modules/_acceptCookie";
@import "modules/_modal";
@import "modules/_loadingspinner";
@import "modules/_tables";
@import "modules/_cms";
@import "modules/_datepicker";

// Views
@import "views/_frontpage";
@import "views/_footer";
@import "views/_header";
@import "views/_quickbasket";
@import "views/_login";
@import "views/_quickorderAndCsv";
@import "views/_helppages";

// Product views
@import "views/product/_productsearch";
@import "views/product/_productlist";
@import "views/product/_productresulttable";
@import "views/product/_productdetails";
@import "views/product/_productcompare";
@import "views/checkout/_checkout";
@import "views/product/_productdetailsLandingpage";

// Download page
@import "views/download/_download";

// Report Generator
@import "views/reportgenerator/_reportgenerator";

// Rma page
@import "views/rma/_rma";

