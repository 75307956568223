﻿.header {
    background: $header-background;
    position: relative;
    height: 208px;

    @include media-breakpoint-down(md) {
        height: 135px;
    }

    .frontPage & {
        height: 400px;
        background-image: url('../../wwwroot/images/header.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: right;

        @include media-breakpoint-up(xl) {
            //height: 465px;
        }

        @include media-breakpoint-down(md) {
            height: 300px;
            background-size: cover;
            background-position-x: 30%;
        }
    }

    &__wrapper {
        height: 100px;
        background-color: #FFF;
        border-radius: 10px 10px 0 0;
        top: 20px;
        padding-bottom: 0;

        @include media-breakpoint-down(md) {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            z-index: 4500;
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -15px;
            width: 100%;
            height: 10px;
            margin-left: -5px;
            background-color: #fff;
            border-radius: 0 0 10px 10px;
            transition: all .4s;
        }
    }

    &__logo {
        margin-top: 22px;
        margin-left: 15px;
        display: block;
        height: 70px;
        background-image: url('../../wwwroot/images/logo_color.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    @include media-breakpoint-down(md) {
        &__wrapper {
            border-radius: 0;
            top: 0;
            height: 60px;

            &:after {
                height: 0px;
                bottom: 0px;
            }
        }

        &__logo {           
            width: 75px;
            margin-top: -5px;
            margin-left: 0px;
        }
    }
}
/* Language selector */

.language {
    float: right;
    cursor: pointer;
    user-select: none;
    padding: 5px 5px 5px 5px;
    margin-top: -5px;
    border: 1px solid transparent;
    width: 125px;

    &.show {
        border: 1px solid $text-6;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .language__container {
            display: block;
        }
    }

    &__container {
        display: none;
        float: right;
        clear: both;
    }

    > .topmenu__link {
        pointer-events: none;

        &:hover, &:focus, &:active {
            color: inherit;
            text-decoration: none;
        }
    }

    .languageselector {
        &__list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            border: 1px solid $text-6;
            border-top: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: white;
            z-index: 3000;
            position: absolute;
            right: 5px;
            margin-top: 6px;
            width: 125px;

            &-header {
                display: none;
            }
        }

        &__item {
            user-select: none;
            font-size: 16px;

            &:hover {
                background-color: $list-hover;

                .languageselector__link {
                    color: white;
                }
            }
        }

        &__link {
            text-decoration: none;
            display: block;
            height: 100%;
            width: 100%;
            padding: 6px;

            &.selected {
                background-color: $list-hover;
                color: white;
            }
        }
    }
}


/* Menus */

.topmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 10px;

    @include media-breakpoint-down(lg) {
        margin-bottom: 0;
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 19px 0 0;
    }

    &__item {
        float: left;
        font-size: 15px;
        padding: 0px 10px;
        border-left: 1px solid black;

        &:first-of-type, &--noborder {
            border-left: 0;
        }

        &--search {
            margin-top: -7px;
            padding: 0 20px;

            @include media-breakpoint-down(lg) {
                padding: 0;
            }
        }

        img {
            vertical-align: inherit;
        }

        &.userinfo {
            text-align: right;
            position: absolute;
            margin-top: 26px;
            right: -3px;

            .notice {
                color: $text-4;
            }
        }
    }

    &__contact {
        display: inline-flex;
        align-items: flex-end;
        line-height: 22px;
    }

    &__contact-note {
        padding-right: 10px;
        margin-bottom: 1px;
    }

    input[type=text].topmenu__search {
        height: 30px;
        width: 165px;
        line-height: 30px;

        @include media-breakpoint-down(lg) {
            width: 120px;
        }
    }

    &__language {
        padding-right: 0;

        .topmenu__link {
            background-repeat: no-repeat;
            background-position: right 50%;
            padding-right: 25px;
            display: block;
            float: left;
        }
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}

.mainmenu {

    @include media-breakpoint-down(lg) {
        top: -10px;
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        @include media-breakpoint-up(xl) {
            &--flex {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;

                .mainmenu__item {
                    flex: 0 0 auto;
                }
            }
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__item {
        float: left;
        margin-right: 14px;

        &--last {
            margin-right: 0;
        }

        @include media-breakpoint-down(lg) {
            margin-right: 10px;
        }
    }

    &__link {
        color: $nissens-blue;
        font-size: 16px;
        transition: color 0.3s;

        &:hover {
            color: $text-4 !important;
            text-decoration: none;
        }

        &:focus {
            text-decoration: none;
        }

        @include media-breakpoint-down(lg) {
            font-size: 14px;
        }
    }
}
// This is ugly! The menu doesn't know what page is selected though, so this is the best we can do for now :(
body.productPage .productPage > .mainmenu__link,
body.checkoutPages .productPage > .mainmenu__link,
body.frontPage .frontPage > .mainmenu__link,
body.downloadPage .downloadPage > .mainmenu__link {
    color: $text-4;
    font-weight: 600;
    border-bottom: 3px solid $text-4;
    padding-bottom: 6px;

    @include media-breakpoint-down(lg) {
        padding-bottom: 3px;
    }
}

/* Mobile menu */
.mobilemenu, .mobilemenu__sidebar {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $nissens-blue;
    z-index: 4900;
    right: 100%;
    line-height: 60px;
    transition: right linear 0.3s;

    .mobilemenu-active & {
        right: 0%;
        overflow: auto;
    }

    &__list, .languageselector__list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        &-header {
            height: 60px;
            color: white;
            font-size: 20px;
            font-weight: 600;
            padding-left: 30px;
            background-color: rgba(0,0,0,0.2);
        }
    }

    &__item, .languageselector__item {
        height: 60px;
        border-bottom: 1px solid rgba(255,255,255,0.3);

        &--admin {
            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    &__link, .languageselector__link {
        color: white;
        font-size: 20px;
        font-weight: 600;
        padding-left: 30px;
        width: 100%;
        display: block;

        &:active, &:focus, &:hover {
            outline: none;
            color: white;
            text-decoration: none;
            background-color: rgba(0,0,0,0.2);
        }

        &::after {
            content: '';
            box-sizing: border-box;
            height: 11px;
            width: 11px;
            border-top: 2px solid #fff;
            border-right: 2px solid white;
            -webkit-transform: scaleY(-1) rotate(270deg);
            transform: scaleY(-1) rotate(45deg);
            display: block;
            position: relative;
            float: right;
            /* margin: auto 0; */
            margin-top: 23px;
            margin-right: 20px;
        }
    }

    &__button {
        z-index: 5000;
        position: fixed;
        top: 8px;
        right: 10px;
        height: 43px;
        width: 50px;
        border-radius: 5px;      
        text-align: center;       
        background-color: $text-4;       
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba(0,76,130,.2);

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &-text {
            pointer-events: none;
            user-select: none;
        }

        &--close {
            z-index: 5001;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICA8cGF0aCBkPSJNMS44OTMxNjg3Ljc4NzEyMTIzTDE1LjM5MjQ4IDE0LjI4NjQzMjVsLTEuNDE0MjEzNTcgMS40MTQyMTM1OEwuNDc4OTU1MTMgMi4yMDEzMzQ3OHoiLz4gICAgPHBhdGggZD0iTTE1LjM5MjQ3OTY1IDIuMjAxMzM0MzNMMS44OTMxNjgzNyAxNS43MDA2NDU2LjQ3ODk1NDggMTQuMjg2NDMyMDYgMTMuOTc4MjY2MS43ODcxMjA3NXoiLz4gIDwvZz48L3N2Zz4=);
            background-position: 50% 50%;
            background-repeat: no-repeat;

            .mobilemenu__button-text {
                display: none;
            }
        }
    }

    &__sidebar {
        left: 100%;
        top: 0;
        transition: left linear 0.3s;
        overflow: scroll;

        &.show {
            left: 0;
        }

        .languageselector__link {
            &.selected {
                background-color: rgba(0,0,0,0.2);

                &::after {
                    display: block;
                    border: none;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICA8cGF0aCBkPSJNMiA1LjZMNC4yODU3MTQzIDggMTAgMiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNGRkYiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==);
                    background-repeat: no-repeat;
                    transform: scale(1.5);
                    height: 20px;
                    width: 20px;
                    top: 3px;
                }
            }

            &::after {
                display: none;
            }
        }
    }
}
