﻿.productList {
    .filtrationLine {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &--top {
            @include media-breakpoint-down(md) {
                display: none;
            }
            margin-top: 10px;
        }
        &--bottom {
            justify-content: space-around;
        }
         .paging {
            a {
                height: 30px;
                width: 30px;
                line-height: 27px;
                border: 1px solid $light-grey;
                border-radius: 4px;
                background-color: #FFFFFF;
                color: $text-5;
                display: inline-block;
                text-align: center;
                margin-left: 12px;
                margin-bottom: 6px;
                float: left;

                &.forward {
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        height: 8px;
                        width: 8px;
                        top: 10px;
                        left: 8px;
                        transform: rotate(45deg);
                        border-top: 1px solid $grey;
                        border-right: 1px solid $grey;
                    }
                }

                &.back {
                    position: relative;
                    &:after {
                        content: '';
                        position: absolute;
                        height: 8px;
                        width: 8px;
                        top: 10px;
                        left: 12px;
                        transform: rotate(225deg);
                        border-top: 1px solid $grey;
                        border-right: 1px solid $grey;
                    }
                }

                &:hover {
                    text-decoration: none;
                    border: 1px solid $text-4;
                }
                &:first-child {
                    margin-left: 0;
                }
                &.selected {
                    background-color: $text-4;
                    color: white;
                    border: 1px solid $text-4;
                }
            
            }
        }
        .compare, .results {

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .results {
            width: 300px;
            display: flex;
            justify-content: flex-end;

            @include media-breakpoint-down(md) {
                display: none;
            }

            .carsfound {
                margin-top: 3px;
                margin-right: 20px;
                color: $grey;
            }
            .hDropdown {
                width: 75px;
                &+ .select2-container {
                    margin: 0;
                }
            }
        }
        .compare {

            display: flex;
            align-items: center;

            .submitCompare {
                display: none;
                margin-left: 5px;
                &:hover {
                    color: white;
                }
            }
            .c-checkbox {
                margin: 0;
                &__styling {
                    margin: 0;
                    padding-left: 30px;
                    user-select: none;
                    &:before {
                        margin: 4px;
                    }
                    &:after {
                        top: 9px;
                        left: 9px;
                    }
                }

                .noPointer{
                    cursor: default;
                }
            }
        }
    }
   
}
